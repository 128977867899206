.template--wishlist {
	.product-listing--item {
		max-width: 310px;
		width: calc(310px / $container * 100%);
		margin-right: calc(15px / $container * 100%);
		margin-bottom: 52px;

		&:nth-child(4n) {
			margin-right: 0;
		}

		@media only screen and (max-width: $bs-sm) {
			max-width: none;
			width: calc(50% - calc((14px / $container * 100%)/2));
			margin-right: calc(14px / $container * 100%)!important;
			margin-bottom: 42px;

			&:nth-child(2n) {
				margin-right: 0!important;
			}
		}

		@media only screen and (max-width: $bs-xs) {
			width: 100%;
			margin-right: 0!important;
		}
	}
}
