.ate--text-horz {
	.container {
		max-width: 968px;
	}
	
	aside {
		max-width: 421px;
		width: calc(421px / 968px * 100%);
		margin-right: 17px;
		
		& * {
			color: #303030;
		}

		h2 {
			margin-bottom: 0;

			@media only screen and (max-width: $bs-sm) {
				margin-bottom: 20px;
			}
		}
	}
	
	main {
		max-width: 530px;
		width: calc(530px / 968px * 100%);
	}
	
	@media only screen and (max-width: $bs-sm) {
		aside, main {
			max-width: none;
			width: 100%;
		}
		
		aside {
			margin-bottom: 20px;
		}
	}
}

$ateTextEditorImgContainer: 1080px;
.ate--texteditor-img {
  .h2, h2 {
    margin-bottom: 30px;
		font-family:"General Sans Medium";
		font-weight: 600;
		font-size: 50px;
		line-height: 1.2;
  }
  
  .container {
    max-width: $ateTextEditorImgContainer;
  }
  
  picture {
    max-width: 530px;
    width: calc(530px / $ateTextEditorImgContainer * 100%);
    margin-right: calc(130px / $ateTextEditorImgContainer * 100%);
  }

  &--text {
    max-width: 420px;
    width: calc(420px / $ateTextEditorImgContainer * 100%);

    h3 {
      margin-bottom: 30px;
      font-size: 50px;
      line-height: 1.2;

      // @media only screen and (max-width: $bs-xs) {
      @media only screen and (max-width: $bs-sm) {
        font-size: 35px;
        line-height: 1.28;
      }

      @media only screen and (max-width: $bs-xs) {
        margin-bottom: 20px;
      }
    }
  }
  
  .ate--texteditor-img--utility {
    margin-top: 30px;
  }

  @media only screen and (max-width: $bs-xs) {
    picture, &--text {
      max-width: none;
      width: 100%;
      margin-right: 0;
    }

    picture {
      display: block;
      margin-bottom: 30px;
    }
    
    .ate--texteditor-img--utility {
      margin-top: 20px;
      text-align: center;
    }
  }
}

.ate--background-title-text {
	margin: 120px 0;

	@media only screen and (max-width: $bs-sm) {
		margin: 93px 0 90px 0;
	}

	&--wrapper {
		position: relative;
		padding: 60px 0 54px 0;

		@media only screen and (max-width: $bs-sm) {
			padding: 30px 0;
		}

		&:before {
			content: "";
			background-color: var(--dtm-orange);
			opacity: 0.2;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.container {
			max-width: 640px + $padding-left + $padding-right;
		}
	}
}

// styling from the phase1 website
.ate__text-horz {
	.container {
		max-width: 968px;
	}

	aside {
		max-width: 421px;
		width: calc(421px / 968px * 100%);
		margin-right: 17px;

		& * {
			color: rgba(125, 125, 125, 1);
		}
	}

	main {
		max-width: 530px;
		width: calc(530px / 968px * 100%);
	}

	@media only screen and (max-width: $bs-sm) {
		aside, main {
			max-width: none;
			width: 100%;
		}

		aside {
			margin-bottom: 20px;
		}
	}
}

$ateTextEditorImgContainer: 1080px;
.ate__texteditor-img {
  .h2, h2 {
    text-transform: uppercase;
    color: #7D7D7D;
    margin-bottom: 61px;
  }

  .container {
    max-width: $ateTextEditorImgContainer;
  }

  picture {
    max-width: 530px;
    width: calc(530px / $ateTextEditorImgContainer * 100%);
    margin-right: calc(130px / $ateTextEditorImgContainer * 100%);
  }

  &--text {
    max-width: 420px;
    width: calc(420px / $ateTextEditorImgContainer * 100%);

    h3 {
      margin-bottom: 30px;
      font-size: 50px;
      line-height: 1.2;

      @media only screen and (max-width: $bs-xs) {
        margin-bottom: 20px;
      }

      @media only screen and (max-width: $bs-sm) {
        font-size: 35px;
        line-height: 1.28;
      }
    }
  }

  .ate__texteditor-img--utility {
    margin-top: 30px;
  }

  @media only screen and (max-width: $bs-xs) {
    picture, &--text {
      max-width: none;
      width: 100%;
      margin-right: 0;
    }

    picture {
      display: block;
      margin-bottom: 30px;
    }

    .ate__texteditor-img--utility {
      margin-top: 20px;
      text-align: center;
    }
  }
}

.ate--img {
	text-align: center;
  margin: 60px 0 120px 0;

  @media only screen and (max-width: $bs-xs) {
    margin: 86px 0 90px 0;
  }
}

.ate__textEditor {
  margin: 60px 0 120px 0;
  // text-align: center;

  @media only screen and (max-width: $bs-xs) {
    margin: 86px 0 90px 0;
  }

  .container {
    max-width: 860px + $padding-left + $padding-right;
  }

  .ate__textEditor--text {
    *:last-child {
      margin-bottom: 0;
    }
  }

  &._earth-text {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .earth-animation {
      position: absolute;
      left: -400px;
      order: 2;

      @media only screen and (max-width: $bs-md) {
        left: -300px;
      }

      @media only screen and (max-width: $bs-sm) {
        position: relative;
        left: -100px;
      }
    }

    main {
      max-width: 530px;
      order: 1;

      @media only screen and (max-width: $bs-sm) {
        max-width: none;
      }
    }
  }
}

.ate__textEditorImgImg {
  margin: 95px 0;

  main {
    max-width: 530px;
    width: calc(530px / $container * 100%);

    @media only screen and (max-width: $bs-xs) {
      max-width: none;
      width: 100%;
    }
  }

  picture {
    font-size: 1vw;
    max-width: calc(490px + 150px);
    width: calc((490px + 150px) / $container * 100%);
    box-sizing: content-box;
    display: block;
    text-align: right;

    @media only screen and (min-width: $container) {
      font-size: inherit;
    }

    @media only screen and (max-width: $bs-xs) {
      max-width: none;
      width: calc(100% + $padding-right);
    }

    .ate__textEditorImgImg--picture {
      &-wrapper {
        position: relative;
        padding-bottom: 1.68vw;
        display: inline-block;

        @media only screen and (min-width: $container) {
          padding-bottom: 30px;
        }

        @media only screen and (max-width: $bs-xs) {
          padding-bottom: 6.72vw;
        }
      }

      &-fkgd {
        position: absolute;
        bottom: 0;
        width: 20vw;

        @media only screen and (min-width: $container) {
          width: 360px;
        }

        @media only screen and (max-width: $bs-xs) {
          width: 50vw;
        }
      }
    }
  }

  &.img-right {
    main {
      order: 1;
      margin-right: calc(240px / $container * 100%);

      @media only screen and (max-width: $bs-md) {
        margin-right: calc(120px / $container * 100%);
      }

      @media only screen and (max-width: $bs-xs) {
        margin-right: 0;
      }
    }

    picture {
      order: 2;

      .ate__textEditorImgImg--picture-fkgd {
        left: 0;
      }

      .ate__textEditorImgImg--picture {
        &-wrapper {
          padding-left: 8.4vw;

          @media only screen and (min-width: $container) {
            padding-left: 150px;
          }

          @media only screen and (max-width: $bs-xs) {
            padding-left: 33.6vw;
          }
        }
      }
    }
  }

  &.img-left {
    main {
      order: 2;
      margin-left: calc(240px / $container * 100%);

      @media only screen and (max-width: $bs-md) {
        margin-left: calc(120px / $container * 100%);
      }

      @media only screen and (max-width: $bs-xs) {
        margin-left: 0;
      }
    }

    picture {
      order: 1;

      .ate__textEditorImgImg--picture-fkgd {
        right: 0;
      }

      .ate__textEditorImgImg--picture {
        &-wrapper {
          padding-right: 8.4vw;

          @media only screen and (min-width: $container) {
            padding-right: 150px;
          }

          @media only screen and (max-width: $bs-xs) {
            padding-right: 33.6vw;
          }
        }
      }
    }
  }
}

.ate__textEditorImg {
  margin: 95px 0;

  main {
    max-width: 530px;
    width: calc(530px / $container * 100%);

    @media only screen and (max-width: $bs-xs) {
      max-width: none;
      width: 100%;
    }
  }

  picture {
    font-size: 1vw;
    max-width: calc(490px + 150px);
    width: calc((490px + 150px) / $container * 100%);
    box-sizing: content-box;
    display: block;
    text-align: right;

    @media only screen and (min-width: $container) {
      font-size: inherit;
    }

    @media only screen and (max-width: $bs-xs) {
      max-width: none;
      width: calc(100% + $padding-right);
    }

    .ate__textEditorImg--picture {
      &-wrapper {
        position: relative;
        padding-bottom: 1.68vw;
        display: inline-block;

        @media only screen and (min-width: $container) {
          padding-bottom: 30px;
        }

        @media only screen and (max-width: $bs-xs) {
          padding-bottom: 6.72vw;
        }
      }

      &-fkgd {
        position: absolute;
        bottom: 0;
        width: 20vw;

        @media only screen and (min-width: $container) {
          width: 360px;
        }

        @media only screen and (max-width: $bs-xs) {
          width: 80vw;
        }
      }
    }
  }

  &.img-right {
    main {
      order: 1;
      margin-right: calc(240px / $container * 100%);

      @media only screen and (max-width: $bs-md) {
        margin-right: calc(120px / $container * 100%);
      }

      @media only screen and (max-width: $bs-xs) {
        margin-right: 0;
      }
    }

    picture {
      order: 2;

      .ate__textEditorImg--picture-fkgd {
        left: 0;
      }

      .ate__textEditorImg--picture {
        &-wrapper {
          padding-left: 8.4vw;

          @media only screen and (min-width: $container) {
            padding-left: 150px;
          }

          @media only screen and (max-width: $bs-xs) {
            padding-left: 33.6vw;
          }
        }
      }
    }
  }

  &.img-left {
    main {
      order: 2;
      margin-left: calc(240px / $container * 100%);

      @media only screen and (max-width: $bs-md) {
        margin-left: calc(120px / $container * 100%);
      }

      @media only screen and (max-width: $bs-xs) {
        margin-left: 0;
      }
    }

    picture {
      order: 1;

      .ate__textEditorImg--picture-fkgd {
        right: 0;
      }

      .ate__textEditorImg--picture {
        &-wrapper {
          padding-right: 8.4vw;

          @media only screen and (min-width: $container) {
            padding-right: 150px;
          }

          @media only screen and (max-width: $bs-xs) {
            padding-right: 33.6vw;
          }
        }
      }
    }
  }
}

