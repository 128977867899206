@import "home-hero.scss";

.home.page {
	.carousel-style--1 {
		margin: 120px 0;

		@media only screen and (max-width: $bs-md) {
			margin: 90px 0;
		}
	}

  .home--news {
    background-color: var(--dtm-cream);
		margin: 56px 0 120px 0;
		padding: 59px 0 60px 0;

		@media only screen and (max-width: $bs-sm) {
			margin: 45px 0 90px 0;
			padding: 45px 0 48px 0;
		}

    .carousel--item--content {
      background-color: #ffffff!important;
    }
  }

	.ate--text-horz {
		margin: 123px 0 122px 0;

		@media only screen and (max-width: $bs-sm) {
			margin: 90px 0;
		}
	}

	.ate--texteditor-img {
		margin: 120px 0;

		@media only screen and (max-width: $bs-sm) {
			margin: 90px 0 93px 0;
		}
	}
}

.home.page {
	.section--what-is-the-marketplace {
		margin: 50px 0;

		@media only screen and (max-width: $bs-sm) {
			margin: 90px 0;
		}

		.section--title {
			text-align: center;
			margin-bottom: 60px;
			text-transform: uppercase;

			@media only screen and (max-width: $bs-sm) {
				margin-bottom: 30px;
			}
		}

		&--text-items {
			max-width: 550px;
			width: calc(550px / 1300px) * 100%;

			@media only screen and (max-width: $bs-sm) {
				max-width: none;
				width: 100%;
			}
		}

		&--text-item {
			position: relative;
			margin-bottom: 20px;
			padding: 32px 34px;

			@media only screen and (max-width: $bs-sm) {
				padding: 23px 22px;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0.2;
			}

			&--sell {
				h4 {
					padding-left: 45px;
					background-repeat: no-repeat;
					background-image: url(../img/icon/icon--sell.svg);

					@media only screen and (max-width: $bs-sm) {
						padding-left: 30px;
						background-size: 25px auto;
					}
				}
				&:before { background-color: var(--dtm-sell); } 
			}

			&--swap {
				h4 {
					padding-left: 45px;
					background-repeat: no-repeat;
					background-image: url(../img/icon/icon--swap.svg);

					@media only screen and (max-width: $bs-sm) {
						padding-left: 30px;
						background-size: 25px auto;
					}
				}
				&:before { background-color: var(--dtm-swap); } 
			}

			&--swap-donation {
				h4 {
					padding-left: 45px;
					background-repeat: no-repeat;
					background-image: url(../img/icon/icon--swap-donation.svg);

					@media only screen and (max-width: $bs-sm) {
						padding-left: 30px;
						background-size: 25px auto;
					}
				}
				&:before { background-color: var(--dtm-swap-donation); } 
			}
		}

		picture {
			margin-left: calc(67px / 1300px) * 100%;
			max-width: 683px;
			width: calc(683px / 1300px) * 100%;
			display: inline-block;

			@media only screen and (max-width: $bs-sm) {
				margin-left: 0;
				margin-top: 30px;
				max-width: none;
				width: 100%;
			}
		}

		footer {
			margin-top: 61px;

			@media only screen and (max-width: $bs-xs) {
				margin-top: 30px;
			}
		}
	}
}

.home.page {
	.tabbed-content {
		ul.ui-tabs-nav {
			margin-bottom: 0;

			li:before { display:none; }

			a {
				display: block;
				text-align: center;
				padding: 25px 20px;
				text-decoration: none!important;
				
				h3 {
					margin-bottom: 0;
				}
			}
		}
	}

  .tabbed-content--content {

    & * {
      text-decoration: none!important;
    }

    &--background-color {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
    }

    &#tabs-1 {
      padding: 40px 0;
    }

    &#tabs-2 {
      padding: 60px 70px;
    }
  }
  
  .home--tabbed-content {
    &--service-item {
      max-width: 310px;
      width: calc(310px / $container * 100%);
      margin-right: calc(77px / $container * 100%);
      text-align: center;

      &:nth-child(3n) {
        margin-right: 0;
      }

      @media only screen and (max-width: $bs-md) {
        margin-right: 0;
        margin-bottom: 40px;
        width: 100%;
      }

      picture {
        display: inline-block;
        max-width: 310px;
        position: relative;
        margin-bottom: 30px;

        svg {
          max-width: 230px;
          width: 100%;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          padding: 20px;
        }
      }
    }

		.ui-accordion-header {
			@media only screen and (max-width: $bs-xs) {
				padding: 20px 30px;
			}
		}
  }

	.accordion {
		h3 {
			margin-bottom: 0;

			& + div {
				padding: 25px 30px;
			}
		}
	}
}



$serviceListingRowContainer: 1080px;
.home.page {
	.service-listing-rows {
		margin: 120px 0 156px 0;

		@media only screen and (max-width: $bs-sm) {
			margin: 90px 0;
		}

		h2 {
			text-transform: uppercase;
			color: #7D7D7D;
			margin-bottom: 61px;
		}

		.service-listing-item {
			margin-right: calc(75px/$serviceListingRowContainer * 100%);
			display: inline-block;
			width: calc(310px/$serviceListingRowContainer * 100%);
			margin-bottom: 50px;

			&:nth-child(3n) {
				margin-right: 0;
			}

			@media only screen and (max-width: $bs-xs) {
				width: 49%;
				margin-right: 2%!important;
				padding: 0 30px;
				margin-bottom: 25px;

				&:nth-child(2n) {
					margin-right: 0!important;
				}
			}

			&--wrapper {
				position: relative;
				width: 100%;
				padding-bottom: 100%;
			}

			&--frame, picture, &--tagline {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			&--frame, picture {
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
			}

			picture {
				background-size: 35%;
				transition: opacity 0.5s ease-in-out;
			}

			&--tagline {
				padding: px2vw(60px);
				text-align: center;
				opacity: 0;
				pointer-events: none;
				transition: opacity 0.5s ease-in-out;

				@media only screen and (min-width: $container) {
					padding: 60px;
				}

				@media only screen and (max-width: $bs-xs) {
					padding: 12vw;
				}

				div {
					border-radius: 50%;
					color: #FFFEFA;
					width: 100%;
					height: 100%;

					span {
						display: block;
						padding: 20px;
					}
				}
			}

			&:hover {
				picture {
					opacity: 0;
					pointer-events: none;
				}

				.service-listing-item--tagline {
					opacity: 1;
					pointer-events: auto;
				}
			}

		}
	}
}

$sectionProductCategoryContainerWidth: 866px;
.section--product-category {
	&--items {
		margin: 120px 0;

		@media only screen and (max-width: $bs-sm) {
			margin: 90px 0;
		}

		@media only screen and (max-width: $bs-xs) {
			padding-top: 96px;

			.scroll-trigger--wrapper {
				height: 100vh;
			}

			h3 {
				margin-bottom: 30px;
			}
		}

		.container {
			max-width: $sectionProductCategoryContainerWidth + $padding-left + $padding-right;
		}
	}

	&--item {
		max-width: 200px;
		width: calc(200px / $sectionProductCategoryContainerWidth * 100%);
		margin-right: calc(22px / $sectionProductCategoryContainerWidth * 100%);
		margin-bottom: 30px;
		display: inline-block;
		text-align: center;
		text-decoration: none;
		color: var(--dtm-body-text);

		@media only screen and (max-width: $bs-xs) {
			max-width: none;
			width: 100%;
			margin-right: 0;
		}

		&:nth-child(4n) {
			margin-right: 0;
		}

		picture {
			display: inline-block;
			margin-bottom: 10px;
			position: relative;

			@media only screen and (max-width: $bs-xs) {
				width: 100%;
			}

			&:before {
				content: "";
				display: block;
				width: 100%;
				padding-bottom: 100%;
				border-radius: 50%;
				background-image: url(../img/dtm-frame--green.svg);
				background-repeat: no-repeat;
				background-size: contain;
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
			}

			img {
				padding: px2vw(40px);

				@media only screen and (min-width: $sectionProductCategoryContainerWidth) {
					padding: 30px;
				}

				@media only screen and (max-width: $bs-xs) {
					width: 100%;
					padding: 16vw;
				}
			}
		}

		h4 {
			margin-bottom: 0;
			font-size: px2vw(30px);

			@media only screen and (min-width: $sectionProductCategoryContainerWidth) {
				font-size: 30px;
			}

			@media only screen and (max-width: $bs-xs) {
				font-size: 22px;
			}
		}
	}
}

.home.page {
	.section--trending-items {
		position: relative;
		padding-bottom: px2vw(50px);

		@media only screen and (min-width: $container) {
			padding-bottom: 50px;
		}

		@media only screen and (max-width: $bs-xs) {
			padding-bottom: 30px;
		}

		&:after {
			content: "";
			background-color: var(--dtm-yellow);
			opacity: 0.2;
			display: block;
			width: 100%;
			height: 50%;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: -1;

			@media only screen and (max-width: $bs-sm) {
				height: 65%;
			}
		}

		h2 {
			font-family:"General Sans Medium";
			font-weight: 600;
			font-size: 50px;
			line-height: 1.2;
				margin-bottom: 30px;

			@media only screen and (max-width: $bs-xs) {
				text-align: center;
				font-size: 35px;
				margin-bottom: 45px;
			}
		}

		.product-listing--item {
			max-width: 310px;
			width: calc(310px / $container * 100%);
			margin-right: calc(20px / $container * 100%);

			&:nth-child(4n) {
				margin-right: 0;
			}

			@media only screen and (max-width: $bs-xs) {
				margin-right: 0;
				margin-bottom: 50px;
				max-width: none;
				width: 100%;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.home.page {
	// .section--bkgd-color {
		// margin-bottom: 0;
	// }

	.section--bkgd-color + .section--bkgd-color {
		margin-top: 0;
	}
}

