.instant-messenger {
	aside {
		max-width: 250px;;
		width: 100%;
		border-right: 1px solid var(--dtm-light-green);

		@media only screen and (max-width: $bs-xs) {
			max-width: none;
			border: 0;
		}
	}

	main {
		background-color: #ffffff;

		@media only screen and (max-width: $bs-xs) {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.5s ease-in-out;
			padding-top: 55px;
			padding-bottom: 55px;

			&.active {
				opacity: 1;
				pointer-events: auto;
				z-index: 2;
			}
		}

		.instant-messenger--chat-window--wrapper {
			max-width: 529px;
			width: 100%;
		}
	}
}

.instant-messenger--sessions {
	position: -webkit-sticky;
  position: sticky;
	top: 0;
}

.instant-messenger--session {
	cursor: pointer;
	margin-bottom: 0;
	padding: px2vw(20px) px2vw(40px) px2vw(20px) px2vw(20px);

	@media only screen and (min-width: $container) {
		padding: 20px 40px 20px 20px;
	}

	&:hover, &.active {
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			opacity: 0.3;
			background-color: var(--dtm-light-green);
		}
	}
}

.instant-messenger--chat-window {
	padding: 0 20px;

	.instant-messenger--messages {
		height: 50vh;
		overflow: auto;

		@media only screen and (max-width: $bs-xs) {
			height: auto;
		}
	}

	.instant-messenger--message {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: var(--dtm-light-green);
			opacity: 0.05;
			transition: opacity 0.5s ease-in-out;
		}
		&.message-read {
			&:before {
				opacity: 0;
			}
		}
		margin-bottom: 30px;

		@media only screen and (max-width: $bs-xs) {
			margin-bottom: 20px;
		}
	}

	form {
		position: relative;

		input {
			width: 100%;
			padding-right: 50px;
		}

		button {
			position: absolute;
			right: 17px;
			width: 20px;
			height: 100%;
			padding: 0;
			border: 0;
			background: none;

			&:after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%) rotate(-90deg);
				width: 100%;
				height: 10px;
				background: url(../img/icon/arrow-EB6723.svg) no-repeat;
			}

			span { display: none }
		}
	}
}

.instant-messenger--user-profile {
	&--img {

		img {
			object-fit: cover;
			width: px2vw(50px);
			height: px2vw(50px);
			border-radius: 50%;
			overflow: hidden;
			margin-right: 15px;

			@media only screen and (min-width: $container) {
				width: 50px;
				height: 50px;
			}
		}

		& + div {
			width: 85%;
			word-wrap: anywhere;
		}
	}

	&--username {
		font-family:"Gotham-Bold";
		font-weight: 500;
		font-size: 18px;
		line-height: 1.277;
		margin-bottom: 10px;

		@media only screen and (max-width: $bs-xs) {
			font-size: 15px;
			line-height: 1.33;
		}
	}
}

.instant-messenger--text {
	margin-bottom: 15px;
}

.instant-messenger--chat-window--date-group {
  font-family:"Gotham-Bold";
	font-weight: 500;
	text-align: center;
	margin-top: 30px;
	margin-bottom: 20px;
	display: block;

	@media only screen and (max-width: $bs-xs) {
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

