$productDetailsContainer: calc(1071px + $padding-left + $padding-right);

.product-details {
	// .container { max-width: $productDetailsContainer; }

	.btn--share {
		button {
			background: none;
			border: 0;
			padding: 0;
			position: relative;
			text-indent: 25px;
			font-family:"Gotham-Bold";
			font-weight: 500;
			font-size: 16px;
			line-height: 1.56;
			text-transform: uppercase;
			margin-right: 20px;

			&:before {
				content: "";
				background-image: url(../img/icon/share--303030.svg);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				display: inline-block;
				width: 20px;
				height: 22px;
				position: absolute;
				left: 0;
			}
		}

		.social-media-sharing--icons {
			position: absolute;
			transform: translate(-68%,62%);
			z-index: 2;
			margin-bottom: 0;
			background-color: #EDECBD;
			text-align: center;
			padding: 10px;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.5s ease-in-out;

			ul {
				margin: 0;

				li {
					margin-right: 15px;

					a {
						display: block;
						white-space: nowrap;
						font-family:"Gotham-Bold";
						font-weight: 500;
						font-size: 12px;
						line-height: 1.1;
						min-width: 30px;

						span {
							display: inline-block;
							background-color: #303030;
							color: #FFFEFA;
							padding: 9px 15px;
						}
					}

					&:last-child { margin: 0; }
				}
			}
		}

		&.open {
			button {
				color: var(--dtm-light-green);
				&:before { background-image: url(../img/icon/share--C4C32F.svg); }
			}

			.social-media-sharing--icons {
				opacity: 1;
				pointer-events: auto;
			}
		}
	}

	aside {
		max-width: 631px;
		width: calc(631px / $productDetailsContainer * 100%);
		margin-right: calc(20px / $productDetailsContainer * 100%);

		@media only screen and (max-width: $bs-xs) {
			max-width: none;
			width: 100%;
			margin-right: 0;
			margin-bottom: 30px;
		}

		.slick-slide {
			cursor: pointer;
		}

		.slider-for {
			order: 2;
			max-width: 530px;
			width: calc(530px / 631px * 100%);

			@media only screen and (max-width: $bs-xs) {
				max-width: none;
				width: 100%;
				margin-bottom: 20px;
			}

			.slick-list, .slick-slide {
				min-height: 500px;
			}

			.slick-slide {
				position: relative;
				img {
					object-fit: contain;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}

		.slider-nav {
			order: 1;
			max-width: 74px;
			width: calc(74px / 631px * 100%);
			margin-right: calc(27px / 631px * 100%);

			@media only screen and (max-width: $bs-xs) {
				max-width: none;
				width: 100%;
				margin-right: 0;
			}

			.slick-slide {
				position: relative;
				&:after {
					content: "";
					display: inline-block;
					width: 100%;
					padding-bottom: 100%;
				}

				@meida only screen and (max-width: $bs-xs) {
					max-width: 50px;
				}

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}

				&.slick-current {
					img {
						border: 2px solid var(--dtm-light-green);
					}
				}
			}
		}
	}

	main {
		max-width: 420px;
		width: calc(420px / $productDetailsContainer * 100%);

		@media only screen and (max-width: $bs-xs) {
			max-width: none;
			width: 100%;
			margin-right: 0;
		}
	}

	@media only screen and (max-width: $bs-xs) {
		max-width: none;
		width: 100%;
	}

	section {
		margin-bottom: 20px;
	}

	.button--add-to-cart--wrapper {
		flex-grow: 1;
		margin-right: px2vw(27px);

		@media only screen and (min-width: $container) {
			margin-right: 27px;
		}

		@media only screen and (max-width: $bs-xs) {
			margin-right: px2vw(29px);
		}

		.button--add-to-cart {
			width: 100%;
			text-align: center;

			&:before {
				margin-right: 10px;
			}
		}
	}

	&--star-button {
		span { display: none; }

		background: none;
		border: 0;
		padding: 0;
	}

	&--info-table {

		dl, dt, dd { margin-bottom: 0; }

		dl {
			margin-bottom: 15px;
			display: flex;
			justify-content: space-between;

			dt {
				font-family:"Gotham-Bold";
				font-weight: 500;
				font-size: 18px;
				line-height: 1.277;
				text-transform: uppercase;
			}
		}
	}

	.widget--user-long {
		margin-top: 10px!important;
		margin-bottom: 50px!important;
	}
}
