header.template {
	padding: 26px 0 0 0;

	@media only screen and (max-width: $bs-sm) {
		padding: 26px 0;
	}

  .navbar-brand {
    max-width: 420px;
		width: 40%;
		margin-left: px2vw(50px);

		a { display: inline-block; padding: 0; }

		@media only screen and (min-width: $container) {
			margin-left: 50px;
		}

    @media only screen and (max-width: $bs-sm) {
      max-width: 77px;
			width: 50%;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

$navbarTogglerLineHeight: 3px;
header.template {
  .navbar-toggler {
		position: relative;
    width: 25px;
    height: 15px;
    padding: 0;

    &:before, &:after {
      content: "";
      display: block;
      background-color: rgba(48, 48, 48, 1);
      position: absolute;
      left: 0;
      height: $navbarTogglerLineHeight;
    }

    &:before {
      top: 0;
      width: 100%;
    }

    .navbar-toggler-icon {
      display: block;
      width: 75%;
      height: $navbarTogglerLineHeight;
      background-color: rgba(48, 48, 48, 1);
      margin: 3px 0;

      span {
        display: none;
      }
    }
    
    &:after {
      bottom: 0;
      width: 50%;
    }

    .navbar-toggler-icon, &:after {
      transition: width 0.5s ease-in-out;
    }
    
    &:hover {
      .navbar-toggler-icon, &:after {
        width: 100%;
      }
    }
  }
}

.header--quick-links {
	@media only screen and (max-width: $bs-sm) {
		margin-right: -7.5px;
	}

	a {
		font-family:"General Sans Medium";
		font-weight: 500;
		font-size: px2vw(17px);
		line-height: 1.1;
		margin: 0 px2vw(15px);
		white-space: nowrap;
		text-decoration: none;

		@media only screen and (min-width: $container) {
			font-size: 17px;
			margin: 0 15px;
		}

		@media only screen and (max-width: $bs-sm) {
			a {
				margin: 0 7.5px;

				img {
					max-width: 20px;
				}
			}
		}
	}
}

.header--button-highlight {
	background-color: var(--dtm-green)!important;
	&, & > a {
		color: var(--dtm-cream)!important;
	}
  font-family:"Gotham-Bold"!important;
	font-weight: 500!important;
	line-height: 1.56!important;
	white-space: nowrap!important;
	font-size: px2vw(16px)!important;
	padding: px2vw(13px) px2vw(26px)!important;
	text-transform: uppercase!important;
	margin-right: px2vw(15px)!important;

	@media only screen and (min-width: $container) {
		font-size: 16px!important;
		padding: 13px 26px!important;
		margin-right: 15px!important;
	}
}

.header--search-button {
	button {
		background: none;
		border: 0;

		@media only screen and (max-width: $bs-sm) {
			padding: 0 7.5px;
		}

		img {
			@media only screen and (max-width: $bs-sm) {
				max-width: 20px;
			}
		}
	}

	.quick-search-panel {
		position: absolute;
		left: 0;
		width: 100%;
		z-index: 2;
		opacity: 0;
		pointer-events: none;

		@media only screen and (max-width: $bs-sm) {
			top: 61px;
		}
	}

	form {
		position: relative;

		input {
			border: 0;
			border-bottom: 1px solid #303030;
			border-radius: 0;
			padding-right: 35px;
		}

		button {
			position: absolute;
			right: 0;
			padding: 0;
		}
	}
}

.header--wishlist-badge {
	position:relative;
	background-image: url(../img/icon/heart.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	width: 25px;
	height: 29px;

	&:hover {
		background-image: url(../img/icon/heart--C4C32F.svg);
	}

	label { display: none; }
}

.header--cart-badge {
	position:relative;
	background-image: url(../img/icon/cart--303030.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	width: 30px;
	height: 29px;

	div { display: none; }

	span {
		display: inline-block;
		width: 19px;
		height: 19px;
		background-color: var(--body-text);
		border-radius: 50%;
		position: absolute;
		top: -5px;
		right: -8px;
		color: #FFFEFA;
		font-family:"Gotham-Bold";
		font-weight: 450;
		font-size: 14px;
		line-height: 1.4;
		text-align: center;
	}

	&:hover {
		background-image: url(../img/icon/cart--C4C32F.svg);
		span {
			background-color: #C4C32F;
			color: #303030;
		}
	}
}

.header--im-badge {
	position:relative;
	background-image: url(../img/icon/chat--303030.svg);
	background-repeat: no-repeat;
	background-position: center center;
	width: 30px;
	height: 29px;

	div { display: none; }

	span {
		display: inline-block;
		width: 19px;
		height: 19px;
		background-color: var(--body-text);
		border-radius: 50%;
		position: absolute;
		top: -5px;
		right: -8px;
		color: #FFFEFA;
		font-family:"Gotham-Bold";
		font-weight: 450;
		font-size: 14px;
		line-height: 1.4;
		text-align: center;
	}

	&:hover {
		background-image: url(../img/icon/chat--C4C32F.svg);
		span {
			background-color: #C4C32F;
			color: #303030;
		}
	}
}

// sticky header
header.template {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	background-color: #ffffff;

	& + main.template { 
		margin-top: 160px;

		// margin-top: px2vw(160px;);

		// @media only screen and (min-width: $container) {
			// margin-top: 160px
		// }

		@media only screen and (max-width: $bs-sm) {
			margin-top: 81px!important;
		}
	}
}
