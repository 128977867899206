.earth-animation {
  max-width: calc(854px / $container * 100%);
  width: 854px;

  @media only screen and (max-width: $bs-xs) {
    max-width: none;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .figure {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    margin: auto;

    &-rolling {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg {
        width: 100%;
        height: 100%;
        animation: rolling 20s infinite linear;

        path {
          transition: fill 2s ease;
        }
      }
    }

    &-static {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      width: 100%;
      border-radius: 50%;
      overflow: hidden;
      // padding: px2vw(80px);
      padding: 15%;

      @media only screen and (min-width: $container) {
        padding: 83px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @keyframes rolling {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
