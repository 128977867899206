$newsListingContainer: 1080px;
.news-listing {
  .page-header {
    margin-bottom: 60px;

    @media only screen and (max-width: $bs-sm) {
      margin-bottom: 30px;
    }
  }

  .container {
    max-width: $newsListingContainer + $padding-left + $padding-right;
  }

  .news-listing__item {
    display: block;
    max-width: 340px;
    width: calc(340px / $newsListingContainer * 100%);
    margin-right: calc(30px / $newsListingContainer * 100%);
    margin-bottom: 60px;
    color: inherit;
    text-decoration: none;

    &:nth-child(3n) {
      margin-right: 0;
    }

    @media only screen and (max-width: $bs-xs) {
      max-width: none;
      width: 100%;
      margin-bottom: 20px;
      border-bottom: 1px solid #C4C32F;
    }

    header {
      margin-bottom: 20px;

      h6, time {
        font-size: 14px;
        line-height: 1;

        @media only screen and (max-width: $bs-sm) {
          font-size: 15px;
        }
      }

      time {
        line-height: 1;
      }

      h6 {
        color: #178043;
        margin-bottom: 0;
      }

      time {
        color: #303030;
      }
    }

    &-title {
      margin-bottom: 20px;
      font-family:"General Sans Regular";
      font-weight: 400;
      font-size: 20px;
      line-height: 1.3;

      // @media only screen and (max-width: $bs-xs) {
      @media only screen and (max-width: $bs-sm) {
        font-size: 22px!important;
      }
    }

    &.news-listing__item__hero {
      .news-listing__item-title {
        font-family:"General Sans Regular";
        font-weight: 400;
        font-size: 30px;
        line-height: 1.1;
      }
    }

    &-intro {
      @media only screen and (max-width: $bs-xs) {
        display: none;
      }
    }

    picture {
      display: block;
      position: relative;
      padding-bottom: 47.4%;
      margin-bottom: 20px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__feature-items {
    margin-bottom: 60px;

    .grid-wrapper {
      display: grid;
      grid-template-columns: [col1-start] calc(640px / $newsListingContainer * 100%)  [col2-start] calc(420px / $newsListingContainer * 100%)  [col3-start] calc(420px / $newsListingContainer * 100%) [col3-end];
      grid-template-rows: [row1-start] auto [row2-start] auto [row2-end];
      gap: 20px;

      @media only screen and (max-width: $bs-xs) {
        display: block;
      }

      .news-listing__item {
        grid-column: col2-start / col3-start;
        border-bottom: 1px solid #C4C32F;
        margin-bottom: 20px;
        max-width: none;
        width: auto;
        margin-right: 0;

        &:nth-child(2) {
          grid-row: row1-start;
        }

        &:nth-child(3) {
          grid-row: row2-start;
        }

        &.news-listing__item__hero {
          grid-column: col1-start;
          grid-row: row1-start / row2-end;
          border-bottom: 0;

          @media only screen and (max-width: $bs-xs) {
            border-bottom: 1px solid #C4C32F;
          }

          picture {
            padding-bottom: 55.6%;
          }
        }
      }
    }
  }
}

$newsArticleContainer: 860px;
.news-article {
  .container {
    max-width: $newsArticleContainer + $padding-left + $padding-right;
  }

  .page-header {
    .page-title {
      width: 100%;
      text-align: center;
    }
  }

  header {
    margin-bottom: 20px;

    h6, .news-article__author, time {
      font-size: 18px;
      line-height: 1;

      // @media only screen and (max-width: $bs-xs) {
      @media only screen and (max-width: $bs-sm) {
        font-size: 15px;
      }
    }
    
    h6, .news-article__author {
      font-weight: 700;
    }

    .news-article__author, time {
      line-height: 1;
    }

    h6 {
      color: #178043;
      margin-bottom: 0;
    }

    .news-article__author, time {
      color: #303030;
    }

    time, .news-article__author {

      &:before {
        content: "";
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #EB6723;
        margin: 0 10px;
        position: relative;
        top: -3px;
      }
    }

    @media only screen and (max-width: $bs-xs) {
      time {
        &:before {
          display: none;
        }
      }
    }
  }

  .banner {
    margin-bottom: 60px;
    text-align: center;
  }

  .social-media-sharing {
    h6 {
      color: #178043;
      margin-bottom: 30px;
    }

    &--icons {
      a {
        margin-bottom: 30px;
        display: block;
      }
    }
  }

  .ate {
    margin-bottom: 60px;
  }
}

/*
$newsArticleContainer: 860px;
.news-article {
  .container {
    max-width: $newsArticleContainer + $padding-left + $padding-right;
  }
  
  .page-header {
    .page-title {
      width: 100%;
      text-align: center;
    }
  }

  header {
    margin-bottom: 20px;
    
    h6 {
      color: #178043;
      margin-bottom: 0;
    }

    .news-article--author, time {
      color: #303030;
    }

    time, .news-article--author {

      &:before {
        content: "";
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #EB6723;
        margin: 0 10px;
        position: relative;
        top: -3px;
      }
    }

    @media only screen and (max-width: $bs-xs) {
      time {
        &:before {
          display: none;
        }
      }
    }
  }

  .banner {
    margin-bottom: 60px;
    text-align: center;
  }

  .social-media-sharing {
    h6 {
      color: #178043;
      margin-bottom: 30px;
    }

    &--icons {
      a {
        margin-bottom: 30px;
        display: block;
      }
    }
  }

  .ate {
    margin-bottom: 60px;
  }
}
*/
