.post-product-form {
	.container {
		max-width: 639px + $padding-left + $padding-right;
	}

	section {
		margin-bottom: 60px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.post-product-form--transaction-type {
		.form-group > label {
			margin-bottom: 20px;
			font-weight: 400;
			font-size: 19px;
			line-height: 1.26;
		}
	}

	.post-product-form--photo-upload {
		&--intro {
			margin-bottom: 30px;

			@media only screen and (max-width: $bs-xs) {
				margin-bottom: 15px;
			}

			& * {
				font-family:"Gotham-Book";
				font-weight: 400;
				font-size: 14px;
				line-height: 1.29;

				@media only screen and (max-width: $bs-xs) {
					font-size: 14px;
				}
			}

			h3 {
				font-family:"General Sans Medium";
				font-weight: 600;
				font-size: 30px;
				line-height: 1.26;
				margin-bottom: 10px;

				@media only screen and (max-width: $bs-xs) {
					font-size: 22px;
				}
			}
		}

		.pretty-upload-button + label {
			font-family:"General Sans Medium";
			font-weight: 600;
			font-size: 20px;
			line-height: 1.35;
			display: block;
			margin-top: 10px;
		}
	}

	.accordion {
		border-bottom: 1px solid var(--dtm-light-green);
		margin-bottom: 29px;
		margin-top: -20px;

		.ui-accordion-header {
			font-family:"Gotham-Book";
			font-weight: 350;
			font-size: 19px;
			line-height: 1.26;
			padding: 0;
			padding-top: 20px;
			margin-bottom: 18px;
			border-top: 1px solid var(--dtm-light-green);

			&:first-child {
				border-top: 0;
			}

			&:after {
				content: "";
				background-image: url(../img/icon/arrow-EB6723.svg);
				background-repeat: no-repeat;
				background-size: contain;
				// background-position: right center;
				width: 13px;
				height: 9px;
				right: 10px;
				top: 28px;
			}

			&.ui-state-active {
				&:after {
					content: "";
					transform: rotate(-180deg);
				}
			}
		}

		.ui-accordion-content {
			padding-bottom: 20px;

			.form-group:last-child {
				margin-bottom: 0;
			}
		}
	}

	.product-type--btn {
		margin-right: 22px;
		margin-bottom: px2vw(20px);
		opacity: 0.5;

		&.product-tag--active {
			opacity: 1;
		}

		@media only screen and (min-width: $container) {
			margin-bottom: 20px;
		}

		@media only screen and (max-width: $bs-xs) {
			margin-bottom: 20px;
		}

		&:last-child {
			margin-right: 0;
		}

		&, label {
			cursor: pointer;
		}
	}

	&--item-value {
		.form-field {
			&:before {
				content: attr(data-currency-symbol);
				position: absolute;
				top: 10px;
				left: 20px;
			}
			input {
				text-indent: 50px;
			}
		}

		&.product-sell .form-field:before {
				content: attr(data-currency-symbol);
		}

		&.product-swap .form-field:before {
				content: attr(data-credit-symbol);
		}
	}

	&--handover-preference {
		.form-field {
			position: relative;

			&:after {
				content: "";
				background-image: url(../img/icon/lock-178043.svg);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				display: inline-block;
				width: 21px;
				height: 100%;
				position: absolute;
				right: 20px;
				top: 0;
			}

			input {
				padding-right: 60px;
			}
		}
	}

	&--handover-preference--unlocked .form-field {
		&:after { display: none; }
	}
}
