.profile {
  display: grid;
  grid-template-columns: calc(200em / 18) 1fr;
  grid-column-gap: calc(130 / 1360 * 100%);
  grid-auto-rows: auto;

  @media only screen and (max-width: $bs-sm) {
    grid-template-columns: 100%;
  }

	&-img--wrapper {
    overflow: hidden;
    border-radius: 50%;

    margin-bottom: calc(27em / 18);
    position: relative;
    grid-column: 1 / 2;
    width: calc(200em / 18);

    @media only screen and (max-width: $bs-sm) {
      grid-column: span 1;
      margin-left: auto;
      margin-right: auto;
    }
	}

	&-img--upload-error {
		background-image: url(../img/icon/alert-DC0000.svg);
		background-repeat: no-repeat;
		background-position: left top;
		padding-left: 30px;

		p {
			font-family:"Gotham-Book";
			font-weight: 400;
			font-size: 12px;
			line-height: 1.416;
			color: #DC0000;
		}
	}

	&-img--instructions {
		p {
			font-family:"Gotham-Book";
			font-weight: 400;
			font-size: 12px;
			line-height: 1.416;
			color: #303030;
		}
	}

  &-img {
    &--mobile-none {
      @media only screen and (max-width: $bs-sm){
        display: none;
      }
    }


    &__img {
      padding-top: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
				object-fit: contain;
      }
    }

    &__upload {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.3);
      cursor: pointer;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &-nav {
    max-width: calc(200em / 18);
    grid-column: 1 / 2;

    @media only screen and (max-width: $bs-sm) {
      order: -1;
      grid-column: span 1;
      max-width: 100%;
    }

    &__mobile-wrapper {

      @media only screen and (max-width: $bs-sm) {
        position: fixed;
        background-color: #fff;
        z-index: 2048;
        right: 100%;
        width: 100vw;
        height: calc(100vh - 56px);
        transition: .7s;
        top: 56px;
        padding: calc(50em / 15) calc(60em / 15) 0;
        text-align: center;
        overflow-y: auto;
      }

      &--active {
        right: 0;
      }

      .profile-img {
        display: none;

        @media only screen and (max-width: $bs-sm) {
          display: block;
					position: relative;
					max-width: 200px;
					margin: 0 auto;
        }
      }
    }

    &__username, &__id {
      display: none;

      @media only screen and (max-width: $bs-sm){
        display: block;
      }
    }

    &__id {
      margin-bottom: calc(47em / 14);
      font-family:"Gotham-Bold";
			font-weight: 500;
    }

    &__btn {
      display: none;
      margin-bottom: calc(50em / 18);
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      @media only screen and (max-width: $bs-sm) {
        display: block;
      }
    }

    &__nav {
      margin-bottom: calc(30em / 18);
    }

    &__item {
      margin-bottom: calc(30em / 18);
      max-width: calc(180em / 18);

      @media only screen and (max-width: $bs-sm){
        margin-left: auto;
        margin-right: auto;
        max-width: calc(180em / 15);

      }
    }

    &__link {
			text-transform: uppercase;
      font-family:"Gotham-Bold";
			font-weight: 500;
      display: inline;
      color: var(--body-text);
      font-size: calc(16em / 18);
      text-decoration: none;
      padding: calc(2em / 16) 0;
      border-bottom: 2px solid var(--dtm-yellow);
      line-height: calc(25 / 16);
      transition: .3s;

      &:hover, &:focus, &--active {
        border-color: var(--dtm-orange);
      }

      @media only screen and (max-width: $bs-sm){
        font-size: calc(16em / 15);
      }

    }

    &__actions {
      button {
        width: 100%;
        height: calc(65em / 16);
      }
    }
  }

  &-edit {
    grid-row: 1 / 4;
    grid-column: 2 / 3;

    @media only screen and (max-width: $bs-sm) {
      grid-column: span 1;
      grid-row: span 1;

      .profile-edit {

        &__username, &__id, &__rating {
          text-align: center;
          justify-content: center;
        }
      }

      h4 {
        text-align: center;
      }

    }

    &-img {
      position: relative;
      width: calc(200em / 18);
      border-radius: 50%;
      overflow: hidden;

      &__img {

      }

      &__upload {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity .5s;
        background-color: rgba(0,0,0,0.3);
        padding: 0;
        border: 0;
      }

    }

    &__id {
      font-family: 'Gotham-Bold';
			font-weight: 500;
      font-size: calc(16em / 18);
      margin-bottom: calc(10em / 16);
    }


    &__rating {
      font-family: 'Gotham-Bold';
			font-weight: 500;
      font-size: calc(16em / 18);
      margin-bottom: calc(30em / 16);

      display: flex;

      .star-rating {
        margin-left: calc(10em / 18);
      }
    }

    &__form {
      display: grid;
      grid-template-columns: 100%;
      margin-bottom: calc(40em / 18);

      label {
        margin-bottom: calc(20em / 18);
      }

      span {
        display: block;
        font-family: 'Gotham-Book';
        font-size: calc(19em / 18);
        margin-bottom: calc(15em / 19);
      }

      .pretty-select {
        margin-bottom: calc(15em / 18);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__actions {
      text-align: center;
    }

    &--password {
      .profile-edit {

        &__username, &__id {

          @media only screen and (max-width: $bs-sm){
            display: none;
          }
        }
      }
    }
  }
}

.container--profile {
  max-width: calc(1000em / 18);
}

html[lang="zh-Hant"], html[lang="zh-Hans"] {
	.profile-nav__mobile-wrapper .button-square {
		font-size: px2vw(14px)!important;

		@media only screen and (min-width: $container) {
			font-size: 14px!important;
		}

		@media only screen and (max-width: $bs-xs) {
			font-size: 11px!important;
		}
	}
}
