header.template {
	.navbar {
		ul li:before { display: none; }

		&, & ul {
			padding-left: 0;
			list-style: none;
			margin-bottom: 0;
		}

		&.main-nav {
			background-color: var(--dtm-gold);
			margin-top: 27px;
			position: relative;

			@media only screen and (max-width: $bs-sm) {
				position: fixed;
				top: -100%;
				left: 0;
				width: 100%;
			}

			& .sub-nav {
				// 1st level sub-nav
				background-color: #ebe8b8;
				position: absolute;
				left: 0;
				width: 100%;

				li {
					position: relative;
				}

				.sub-nav {
					.container { padding: 0; }

					// 2nd level sub-nav and onwards
					position: absolute;
					width: auto;
					top: 100%;
					left: 50%;
					transform: translate(-50%,0%);
					background-color: #ebe8b8;
					opacity: 0;
					pointer-events: none;
					margin-top: 20px;

					.nav-link.has-sub {
						padding-right: 50px;

						&:after {
							content: "";
							background-image: url(../img/icon/arrow-EB6723.svg);
							background-repeat: no-repeat;
							background-size: contain;
							position: absolute;
							top: 50%;
							right: 25px;
							transform: translate(0, -50%) rotate(-90deg);
							display: inline-block;
							width: 13px;
							height: 10px;
						}
					}

					&.sub-nav-depth--3, &.sub-nav-depth--4 {
						position: fixed;
						top: 0;
						left: 100%;
						transform: translate(0%,0%);
						height: 100%;
						margin-top: 0;

						&:before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background-color: var(--dtm-cream);
						}
					}
				}
			}

			& .open + .sub-nav {
				// 1st level sub-nav
				display: block;

				.sub-nav {
					// 2nd level sub-nav and onwards
				}
			}
		}

		.nav-link.open + .sub-nav {
			opacity: 1;
			pointer-events: auto;
			transition: opacity 0.5s ease-in;
		}

		.nav-link.open + .sub-nav {
			z-index: 1;
		}
	}

	.nav-item { position: inherit; }

	.nav-link {
		color: #303030;
		font-family:"General Sans Medium";
		font-weight: 500;
		font-size: px2vw(17px);
		line-height: 1.1;
		padding: 15px 25px;
		cursor: pointer;
		white-space: nowrap;

		@media only screen and (min-width: $container) {
			font-size: 17px;
		}
	}
}

