.single-feature-product {
	a { text-decoration: none!important; color: inherit!important }

	.product-tag--type {
		position: absolute;
		top: 0;
		left: px2vw(10px);
		z-index: 1;

		@media only screen and (min-width: $container) {
			left: 10px;
		}
	}

	picture {
		display: inline-block;
		max-width: 420px;
		width: 43.2989690722%;
		margin-right: 13.4020618557%;
		position: relative;
		padding-top: px2vw(15px);

		@media only screen and (min-width: $container) {
			padding-top: 15px;
		}

		@media only screen and (max-width: $bs-xs) {
			max-width: none;
			width: 100%;
			margin-right: 0;
			margin-bottom: 35px;
			padding-top: 15px;
		}

		.single-feature-product--picture--wrapper {
			padding-bottom: 100%;
			overflow: hidden;
			position: relative;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	aside {
		width: 43.2989690722%;
		max-width: 420px;

		@media only screen and (max-width: $bs-xs) {
			max-width: none;
			width: 100%;
		}
	}

	&.reverse {
		picture { order: 2; margin-right: 0; }

		aside { 
			order: 1; 
			margin-right: 13.4020618557%;

			@media only screen and (max-width: $bs-xs) {
				margin-right: 0;
			}
		}
	}

	h4 {
		font-family:"General Sans Medium";
		font-weight: 600;
		font-size: px2vw(30px);
		line-height: 1.26;
		margin-bottom: px2vw(30px);

		@media only screen and (min-width: $container) {
			font-size: 30px;
			margin-bottom: 30px;
		}

		@media only screen and (max-width: $bs-xs) {
			font-size: 22px;
			margin-bottom: 15px;
		}
	}

	h6 {
		font-family:"Gotham-Medium";
		font-weight: 500;
		font-size: px2vw(19px);
		line-height: 1.26;
		color: var(--dtm-green);
		margin-bottom: px2vw(15px);
		text-transform: uppercase;

		@media only screen and (min-width: $container) {
			font-size: 19px;
			margin-bottom: 15px;
		}

		@media only screen and (max-width: $bs-xs) {
			font-size: 17px;
			margin-bottom: 11px;
		}
	}

	&--size, &--price {
		font-family:"Gotham-Book";
		font-weight: 700;
		font-size: px2vw(20px);
		line-height: 1.35;

		@media only screen and (min-width: $container) {
			font-size: 20px;
		}

		@media only screen and (max-width: $bs-xs) {
			font-size: 16px;
		}
	}

	&--utility {
		border-top: 1px solid #303030;
		margin-top: px2vw(15px);
		padding-top: px2vw(8px);

		@media only screen and (min-width: $container) {
			margin-top: 15px;
			padding-top: 8px;
		}

		@media only screen and (max-width: $bs-xs) {
			margin-top: 10px;
			padding-top: 10px;
		}
	}

	.single-feature-product--tag {
		background-repeat: no-repeat;
		padding: 0 0 0 px2vw(30px);
		display: inline-block;
		background-size: cover;
		background-position: left bottom;

		@media only screen and (min-width: $container) {
			padding: 0 0 0 30px;
		}

		@media only screen and (max-width: $bs-xs) {
			padding: 0 0 0 30px;
		}

		label {
			font-family:"Gotham-Bold";
			font-weight: 500;
			font-size: px2vw(16px);
			line-height: 1;
			text-transform: uppercase;
			color: #FFFEFA;
			padding: px2vw(8px) px2vw(10px) px2vw(6px) 0;
			display: block;

			@media only screen and (min-width: $container) {
				font-size: 16px;
				padding: 8px 10px 6px 0;
			}

			@media only screen and (max-width: $bs-xs) {
				font-size: 16px;
				padding: 8px 10px 6px 0;
			}
		}
	}

	.single-feature-product--tag--special-label {
		position: absolute;
		bottom: px2vw(-15px);
		right: px2vw(20px);
		z-index: 1;
		background-image: url(../img/icon/product-tag--EB6723.svg);
		background-size: contain;

		@media only screen and (min-width: $container) {
			bottom: -15px;
			right: 20px;
		}

		@media only screen and (max-width: $bs-xs) {
			bottom: -15px;
			right: 20px;
		}

		label {
			background-color: #EB6723;
		}
	}

	.single-feature-product--tag--type {
		position: absolute;
		top: 0;
		left: px2vw(10px);
		z-index: 1;

		@media only screen and (min-width: $container) {
			left: 10px;
		}
	}

	&.product-sell {
		.single-feature-product--tag--type {
			background-image: url(../img/icon/product-tag--FDC422.svg);

			label {
				background-color: #FDC422;
			}
		}
	}

	&.product-swap {
		.single-feature-product--tag--type {
			background-image: url(../img/icon/product-tag--C4C32F.svg);

			label {
				background-color: #C4C32F;
			}
		}
	}
}

