.footer--lang-switch {
	padding: 20px 30px;

	label, a {
		display: inline-block;
	}

	label {
		margin-right: 10px;
		margin-bottom: 0;
	}

	a {
		display: inline-block;
		margin-right: 20px;
		text-decoration: none;
		color: var(--body-color);

		&:last-child { margin-right: 0; }
	}
}

@media only screen and (max-width: $bs-sm) {
	#navbar-footer {
		.footer--main--nav--wrapper {
			&.show-catalogue {
			}

			&.no-catalogue {
				.col {
					&, & h3 { display: none; }
					&#footer--nav--services { display: block; }
				}
			}
		}
	}

	.footer--quick-links {
		border-style: solid;
		border-color: rgba(125, 125, 125, 0.15);
		border-width: 1px 0;
		padding: 13px 30px;

		a {
			text-decoration: none;
			border-right: 1px solid rgba(125, 125, 125, 0.15);
			font-family:"Gotham-Bold";
			font-weight: 500;

			&:last-child { border-right: 0; }

			&.footer--quick-links--sign-in {
				color: var(--dtm-light-green);
			}
		}
	}
}
