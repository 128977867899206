.template--marketplace {
	header.template + main.template {
		margin-top: 205px;
	}

	.section--marketplace--latest-products {
		.container {
			max-width: calc(1080px + $padding-left + $padding-right);
		}

		position: relative;
		padding-bottom: px2vw(50px);

		@media only screen and (min-width: $container) {
			padding-bottom: 50px;
		}

		@media only screen and (max-width: $bs-xs) {
			padding-bottom: 30px;
		}

		&:after {
			content: "";
			background-color: var(--dtm-yellow);
			opacity: 0.2;
			display: block;
			width: 100%;
			height: 50%;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: -1;

			@media only screen and (max-width: $bs-xs) {
				top: 10%;
				bottom: auto;
				height: calc(100% - 10%);
			}
		}

		h2 {
			font-family:"General Sans Regular";
			font-weight: 400;
			font-size: 50px;
			line-height: 1.2;
			text-align: center;

			@media only screen and (max-width: $bs-xs) {
				font-size: 35px;
				line-height: 1.28;
			}
		}

		.product-listing--item {
			margin-right: px2vw(80px);
			width: 33%;

			@media only screen and (min-width: $container) {
				margin-right: 80px;
			}

			&:nth-child(3n) {
				margin-right: 0;
			}

			@media only screen and (max-width: $bs-xs) {
				margin-right: 0;
				margin-bottom: 50px;
				width: 100%;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	$featureProductsContainer: 1080px;
	.section--marketplace--feature-products {
		.container {
			max-width: calc($featureProductsContainer + $padding-left + $padding-right);
		}

		position: relative;
		padding-bottom: px2vw(50px);

		@media only screen and (min-width: $container) {
			padding-bottom: 50px;
		}

		@media only screen and (max-width: $bs-xs) {
			padding-bottom: 30px;
		}

		&:after {
			content: "";
			background-color: var(--dtm-light-green);
			opacity: 0.2;
			display: block;
			width: 100%;
			height: 50%;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: -1;

			@media only screen and (max-width: $bs-xs) {
				top: 10%;
				bottom: auto;
				height: calc(100% - 10%);
			}
		}

		h2 {
			font-family:"General Sans Regular";
			font-weight: 400;
			font-size: 50px;
			line-height: 1.2;
			text-align: center;

			@media only screen and (max-width: $bs-xs) {
				font-size: 35px;
				line-height: 1.28;
			}
		}

		.product-listing--item {
			max-width: 310px;
			width: calc(310px / $featureProductsContainer * 100%);
			margin-right: calc(80px / $featureProductsContainer * 100%);

			&:nth-child(3n) {
				margin-right: 0;
			}

			@media only screen and (max-width: $bs-xs) {
				margin-right: 0;
				margin-bottom: 50px;
				max-width: none;
				width: 100%;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.section--seasonal-highlights {
		background-color: var(--dtm-cream);
		margin: 135px 0 120px 0;
		padding: 60px 0 52px 0;

		@media only screen and (max-width: $bs-xs) {
			margin: 101px 0 60px 0;
			padding: 30px 0;
		}

		h2 {
			font-family:"General Sans Regular";
			font-weight: 400;
			font-size: 50px;
			line-height: 1.2;
			text-align: center;

			@media only screen and (max-width: $bs-xs) {
				font-size: 35px;
				line-height: 1.28;
			}
		}

		.product-listing--item {
			max-width: 310px;
			width: calc(310px / $container * 100%);
			margin-right: calc(20px / $container * 100%);

			&:nth-child(4n) {
				margin-right: 0;
			}

			@media only screen and (max-width: $bs-xs) {
				margin-right: 0;
				margin-bottom: 50px;
				max-width: none;
				width: 100%;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.ate--background-title-text {
		.ate--background-title-text--wrapper {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0.2;
				background-color: var(--dtm-yellow);
			}
		}
	}

	.section--marketplace--cool-find {
		margin: 120px 0;

		@media only screen and (max-width: $bs-xs) {
			margin: 90px 0;
		}

		&--wrapper {
			.container {
				max-width: 970px + $padding-left + $padding-right;
			}

			position: relative;
			padding: 45px 0;

			@media only screen and (max-width: $bs-xs) {
				padding: 45px 0 51px 0;
			}

			&:after {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				width: 60%;
				height: 100%;
				z-index: -1;
				background-color: var(--dtm-cream);

				@media only screen and (max-width: $bs-xs) {
					width: 100%;
				}
			}
		}
	}

	.section--marketplace--swap-of-the-week {
		margin: 0 0 135px 0;
		padding-top: 117px;

		@media only screen and (max-width: $bs-xs) {
			margin: 0 0 101px 0;
			padding-top: 90px;
		}

		.container {
			max-width: 970px + $padding-left + $padding-right;
		}

		position: relative;

		&:after {
			content: "";
			background-color: var(--dtm-light-green);
			opacity: 0.2;
			display: block;
			width: 100%;
			height: 50%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;

			@media only screen and (max-width: $bs-xs) {
				top: 0;
				bottom: auto;
				height: 50%;
			}
		}

		// &--wrapper {
			// .container {
				// max-width: 970px + $padding-left + $padding-right;
			// }

			// position: relative;
			// padding: 45px 0;

			// @media only screen and (max-width: $bs-xs) {
				// padding: 45px 0 51px 0;
			// }

			// &:after {
				// content: "";
				// position: absolute;
				// top: 0;
				// right: 0;
				// width: 60%;
				// height: 100%;
				// z-index: -1;
				// background-color: var(--dtm-cream);

				// @media only screen and (max-width: $bs-xs) {
					// width: 100%;
				// }
			// }
		// }

	}
}

.marketplace--product-listing--utility-bar {
	min-height: 120px;

	@media only screen and (max-width: $bs-sm) {
		min-height: 0;
		margin-bottom: 52px;
	}
}

.marketplace--product-listing--search {
	max-width: 510px;

	@media only screen and (max-width: $bs-sm) {
		margin-bottom: 15px;
	}

	input {
		max-width: 381px;
		width: calc(381px / 510px * 100%);
		margin-right: calc(16px / 510px * 100%);
		background-image: url(../img/icon/magnify-glass--EB6723.svg);
		background-repeat: no-repeat;
		background-size: 21px auto;
		background-position: left 20px center;
		padding-left: 21px;
		text-indent: 31px;
	}

	select {
		max-width: 113px;
		width: calc(113px / 510px * 100%);
	}
}

.marketplace--product-listing--results-per-page {
	padding-left: px2vw(20px);

	@media only screen and (min-width: $container) {
		padding-left: 20px;
	}

	@media only screen and (max-width: $bs-sm) {
		padding-left: 0;
		margin-top: 15px;
		margin-bottom: 15px;
	}

	label {
		font-size: px2vw(19px);
		line-height: 24px;
		display: inline-block;
		margin-right: px2vw(15px);

		@media only screen and (min-width: $container) {
			font-size: 19px;
			margin-right: 15px;
		}

		@media only screen and (max-width: $bs-sm) {
			font-size: 17px;
			margin-rigth: 20px;
		}
	}

	button {
		font-size: px2vw(18px);
		line-height: 1.27;
		margin-right: px2vw(15px);
		background: none;
		border: 0;
		padding: 0;
		position: relative;

		@media only screen and (min-width: $container) {
			font-size: 18px;
			margin-right: 15px;
		}

		@media only screen and (max-width: $bs-sm) {
			font-size: 15px;
			margin-rigth: 15px;
		}

		&:last-child { margin-right: 0; }

		&:after {
			content: "";
			display: block;
			width: 100%;
			height: 2px;
			background-color: var(--dtm-light-green);
			position: absolute;
			left: 0;
			bottom: -2px;
			opacity: 0;
		}
		&:hover, &.active {
			&:after { opacity: 1; }
		}
	}
}

.marketplace--product-listing {
	.loading-wheel {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		background-color: #ffffff;
	}

	aside {
		max-width: 310px;
		width: calc(310px / $container * 100%);
		margin-right: calc(20px / $container * 100%);

		@media only screen and (max-width: $bs-sm) {
			max-width: none;
			width: 100%;
			margin-right: 0;
		}
	}

	main {
		max-width: 990px;
		width: calc(990px / $container * 100%);

		@media only screen and (max-width: $bs-sm) {
			max-width: none;
			width: 100%;
		}
	}

	&--main {
		margin: 60px 0 75px 0;
	}
}

.marketplace--product-listing--selected-filter-options {
	margin-top: 21px;

	button {
		position: relative;
		background-color: var(--dtm-cream);
		border: 1px solid var(--dtm-light-green);
		font-family:"Gotham-Book";
		font-weight: 400;
		font-size: px2vw(14px);
		line-height: 1.29;
		padding: px2vw(12px) px2vw(20px) px2vw(12px) px2vw(calc(20px + 11px + 10px));
		margin-right: px2vw(15px);
		margin-bottom: px2vw(10px);

		&:before {
			content: "";
			background-image: url(../img/icon/cross-EB6723.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			position: absolute;
			top: 39%;
			left: px2vw(20px);
			display: inline-block;
			width: px2vw(11px);
			height: px2vw(11px);
		}

		@media only screen and (min-width: $container) {
			font-size: 14px;
			padding: 12px 20px 12px calc(20px + 11px + 10px);;
			margin-right: 15px;
			margin-bottom: 10px;

			&:before {
				left: 20px;
				width: 11px;
				height: 11px;
			}
		}

		@media only screen and (max-width: $bs-sm) {
			font-size: 14px;
			padding: 9px 11px 9px 32px;
			margin-right: 15px;
			margin-bottom: 10px;

			&:before {
				top: 36%;
				left: 10px;
				width: 11px;
				height: 11px;
			}
		}

		&.text-link {
			display: inline-block;
			margin-bottom: px2vw(7px);
			font-weight: 400!important;
			font-size: px2vw(14px)!important;
			line-height: 1.29!important;
			&:before { display: none!important; }

			@media only screen and (min-width: $container) {
				margin-bottom: 7px;
				font-size: 14px!important;
			}

			@media only screen and (max-width: $bs-sm) {
				margin-bottom: 7px;
				font-size: 14px!important;
			}
		}
	}
}

.marketplace--product-listing--filter--close-btn {
	span { display: none; }

	// visibility: hidden;
	// @media only screen and (max-width: $bs-md) {
		// visibility: visible;
	// }

	background: none;
	border: 0;
	padding: 0;
	position: absolute;
	top: 20px;
	right: 30px;
	width: 20px;
	height: 20px;

	&:before, &:after {
		content: "";
		display: block;
		width: 100%;
		height: 4px;
		background-color: var(--dtm-orange);
		position: absolute;
		top: 50%;
		left: 50%;
		transform-origin: 0 0;
	}

	&:before {
		transform: rotate(45deg) translate(-50%,-50%);
	}

	&:after {
		transform: rotate(-45deg) translate(-50%,-50%);
	}
}

.marketplace--product-listing--filter {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: auto;
	background-color: var(--dtm-cream);
	padding: 90px 30px 30px 30px;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.5s ease-in-out;

	@media only screen and (max-width: $bs-sm) {
		position: fixed;
		height: 100%;
		z-index: 2;
		overflow: auto;
	}

	&.open {
		opacity: 1;
		pointer-events: auto;
	}

	.ui-accordion-header {
		font-size: px2vw(20px);
		line-height: 1.35;
		padding: 0;
		margin-bottom: px2vw(20px);
		padding-bottom: px2vw(15px);
		border-bottom: 1px solid var(--dtm-light-green);

		@media only screen and (min-width: $container) {
			font-size: 20px;
			margin-bottom: 20px;
			padding-bottom: 15px;
		}

		@media only screen and (max-width: $bs-sm) {
			font-size: 18px;
		}
	}

	// .pretty-checkbox {
		// div { border-radius: 50%; }

		// input:checked + div {
			// &:after { border-radius: 50%; }
		// }
	// }

	.pretty-checkbox, .pretty-radio {
		label {
			font-size: px2vw(19px);

			@media only screen and (min-width: $container) {
				font-size: 19px;
			}

			@media only screen and (max-width: $bs-xs) {
				font-size: 17px;
			}
		}
	}
}

.template--marketplace {
	.marketplace--product-listing--items {
		.product-listing--item {
			max-width: 310px;
			width: calc(310px / 970px * 100%);
			margin-right: calc(20px / 970px * 100%);
			margin-bottom: 52px;

			&:nth-child(3n) {
				margin-right: 0;
			}

			@media only screen and (max-width: $bs-sm) {
				max-width: none;
				width: calc(50% - calc((14px / $container * 100%)/2));
				margin-right: calc(14px / $container * 100%)!important;
				margin-bottom: 42px;

				&:nth-child(2n) {
					margin-right: 0!important;
				}
			}

			@media only screen and (max-width: $bs-xs) {
				width: 100%;
				margin-right: 0!important;
			}
		}
	}
}

