.footer--main {
	background-color: var(--dtm-cream);
	padding-top: 50px;
	padding-bottom: 50px;
	z-index: 0;

	@media only screen and (max-width: $bs-sm) {
		padding: 50px 0;
	}

	nav li:before { display: none; }

	nav > div {
		@media only screen and (max-width: $bs-md) {
			width: 100%;

			.col {
				flex-grow: 0!important;
			}
		}
	}

	@media only screen and (max-width: $bs-sm) {
		.accordion {
			h3 {
				margin-top: 30px;
				padding: 0;
			}

			div:first-child h3 {
				margin-top: 0;
			}
		}
	}

	h3 {
		font-family:"General Sans Bold";
		font-weight: 500;
		font-size: px2vw(17px);
		line-height: 1.1;
		margin-bottom: px2vw(15px);
		text-align: left;

		@media only screen and (min-width: $container) {
			font-size: 17px;
			margin-bottom: 15px;
		}

		@media only screen and (max-width: $bs-sm) {
			font-size: 23px;
			margin-bottom: 15px;
		}
	}

	.accordion .ui-accordion-header {
		padding-left: 0;
		padding-right: 0;
	}

	ul {
		margin: 0; padding: 0;
		list-style: none;
	}

	.nav-link {
		font-size: px2vw(18px);
		line-height: 1.277;
		padding-bottom: px2vw(10px);
		white-space: nowrap;

		@media only screen and (min-width: $container) {
			font-size: 18px;
			padding-bottom: 10px;
		}

		@media only screen and (max-width: $bs-sm) {
			font-size: 15px;
			padding-bottom: 12px;
		}
	}
}

.footer--main {
	.navbar-brand {
		margin-bottom: 15px;
		margin-right: 50px;

		@media only screen and (max-width: $bs-md) {
			margin-right: 0;
			text-align: center;
		}
	}
}

.footer--tagline {
	margin-bottom: 50px;

	@media only screen and (max-width: $bs-md) {
		margin-bottom: 33px;
		text-align: center;
	}
}

.footer--socialmedia {
	margin-left: -5px;

	a {
		padding: 0 5px;
	}
}

.footer--sub {
	background-color: var(--dtm-green);
	padding: 34px 0;

	@media only screen and (max-width: $bs-sm) {
		padding: 30px 0;
	}

	& * {
		color: var(--dtm-cream);
		text-decoration: none;
	}

	nav {
		.nav-link {
			font-size: px2vw(18px);
			line-height: 1.1;
			padding-right: px2vw(30px);
			padding-bottom: 0;
			display: inline-block;

			@media only screen and (min-width: $container) {
				font-size: 18px;
				padding-right: 30px;
			}

			@media only screen and (max-width: $bs-sm) {
				font-size: 15px;
				padding-right: 0;
				padding-bottom: 20px;
			}
		}
	}

	.footer--copyright {
		font-size: px2vw(14px);
		line-height: 1.29;
		margin-top: px2vw(20px);
		margin-bottom: 0;

		@media only screen and (min-width: $container) {
			font-size: 14px;
			margin-top: 20px;
		}

		@media only screen and (max-width: $bs-sm) {
			font-size: 12px;
			margin-top: 20px;
		}
	}

	.footer--fine-print {
		max-width: 310px;

		@media only screen and (max-width: $bs-sm) {
			max-width: none;
		}

		& * {
			text-align: right;
			font-size: px2vw(14px);
			line-height: 1.29;
			margin-bottom: 0;

			@media only screen and (min-width: $container) {
				font-size: 14px;
			}

			@media only screen and (max-width: $bs-sm) {
				font-size: 12px;
				text-align: left;
			}
		}
	}
}

@media only screen and (max-width: $bs-sm) {
	footer.template {
		padding-bottom: 73px;
	}
}

.footer--user-navigation {
	text-align: center;
	padding: 10px 0;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	background-color: #FFFEFA;

	a {
		text-decoration: none;
		color: var(--body-text);
		text-align: center;

		&.footer--user-navigation--post-product {
			color: var(--dtm-green);
		}
	}

	label {
		display: block;
		font-family:"Gotham-Bold";
		font-weight: 500;
		font-size: 12px;
		line-height: 1.1;
	}

	&--user {
		picture {
			display: inline-block;
			max-width: 60px;
			margin-bottom: 10px;
			width: 30px;
			height: 30px;
			overflow: hidden;
			border-radius: 50%;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	&--post-product {
		span { display: none; }

		div {
			background-color: var(--dtm-green);
			display: inline-block;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			margin-bottom: 10px;
			position: relative;

			&:after {
				content: "+";
				color: #ffffff;
				font-size: 20px;
				line-height: 1;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}
	}

	&--login,
	&--wishlist {

		picture {
			display: inline-block;
			max-width: 60px;
			width: 100%;
			height: 30px;
			padding: 3px 0 7px 0;
			margin-bottom: 10px;
			text-align: center;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}

@media only screen and (max-width: $bs-sm) {
	#navbar-footer {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		width: 100%;
		height: 100%;
		align-items: flex-start;
		opacity: 0;
		pointer-events: none;
		flex-direction: column;
		flex-wrap: nowrap;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background-color: var(--dtm-light-green);
			opacity: 0.5;
		}
	}

	body.nav-open {
		#navbar-footer {
			opacity: 1;
			pointer-events: auto;
			transition: opacity 0.5s ease-in-out;
		}
	}

	#navbar-footer {
		.footer--main--nav--wrapper {
			width: 70%;
			height: 100%;
			background-color: var(--background-color);
			overflow: auto;
			padding: 100px 0 0 0;

			&.no-catalogue, &.show-catalogue #footer--nav--marketplace {
				.nav-link {
					font-family:"General Sans Regular";
					font-weight: 600;
					font-size: 23px;
					line-height: 1.17;
					padding-bottom: 30px;

					@media only screen and (max-width: $bs-sm) {
						font-family:"General Sans Semibold";
						font-weight: 500;
					}
				}
			}

			&.no-catalogue.footer--main--nav--wrapper,
			&.show-catalogue #footer--nav--marketplace, .ui-accordion-header, .ui-accordion-header + div {
				padding-left: 30px;
				padding-right: 30px;
			}

			&.show-catalogue {
				.accordion {
					.ui-accordion-header {
						font-family:"Gotham-Book";
						font-weight: 400;
						font-size: 15px;
						line-height: 1;
						border-bottom: 1px solid var(--dark-grey);
						padding-top: 20px;
						padding-bottom: 20px;
						margin-top: 0;
						margin-bottom: 0;

						&:after {
							content: "";
							position: absolute;
							right: 30px;
							top: 0;
							background-image: url(../img/icon/arrow-EB6723.svg);
							background-repeat: no-repeat;
							background-position: center center;
							width: 17px;
							height: 100%;
						}

						& + div {
							padding-top: 12px;
							padding-bottom: 12px;
							border-bottom: 1px solid var(--dark-grey);
							position: relative;

							ul {
								position: relative;
								z-index: 1;
							}

							&:after {
								content: "";
								position: absolute;
								top: 0;
								left: 0;
								z-index: 0;
								width: 100%;
								height: 100%;
								background-color: var(--dtm-light-green);
								opacity: 0.1;
							}
						}

						&.ui-accordion-header-active {
							border: 0;

							&:after {
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}
	}

	#navbar-footer {
		header {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 1;
			width: 70%;
			height: 100px;
			padding-top: 23px;
			padding-left: 30px;
			background-color: var(--background-color);

			.navbar-toggler {
				position: relative;
				width: 30px;
				height: 30px;
				border: 0;

				span { display: none; }

				&:before, &:after {
					content: "";
					width: 100%;
					height: 3px;
					background-color: var(--body-text);
					position: absolute;
					top: 50%;
					left: 50%;
				}

				&:before { transform: translate(-50%,-50%) rotate(45deg); }
				&:after { transform: translate(-50%,-50%) rotate(-45deg); }
			}
		}
	}

	#navbar-footer {
		footer {
			width: 70%;
			background-color: var(--background-color);
		}
	}
}

