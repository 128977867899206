.carousel {
  .owl-stage-outer {
    width: 99.8%;
  }

  .owl-stage {
    display: flex;
  }

  &--item {
    height: 100%;

    & > div {
      height: 100%;
    }

    &--content, picture {
      width: 50%;

      @media only screen and (max-width: $bs-lg) {
        width: 100%;
      }
    }

    &--title span {
      margin-top: 30px;
      display: block;
    }

    &--text {
      margin-top: 20px;
    }

    &--title, &--title *, &--text * {
      color: #ffffff;
    }

    picture {
      position: relative;
      
      @media only screen and (max-width: $bs-md) {
        display: block;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--content {
      &-wrapper {
        max-width: 418px;
      }
      
      & *:last-child {
        margin-bottom: 0;
      }
    }
  }

  .owl-prev, .owl-next {
    position: absolute;
    top: 50%;

    span {
      display: none;
    }

    background-repeat: no-repeat;
    background-size: contain;
    width: 50px;
    height: 28px;

    @media only screen and (max-width: $bs-lg) {
      width: 25px;
      height: 14px;
    }
  }
  
  .owl-prev {
    transform: rotate(180deg);
  }

  .owl-dots {
    text-align: center;
    margin-top: 20px;
    line-height: 0;

		@media only screen and (max-width: $bs-sm) {
			margin-bottom: 30px;
		}

    .owl-dot {
      display: inline-block;
      width: 41px;
      height: 8px;
      border: 1px solid #C4C32F;
      margin-right: 13px;

      @media only screen and (max-width: $bs-xs) {
        width: px2vw(82px);
        height: px2vw(16px);
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        background-color: #c4c32f;
      }
    }
  }
  
  &.carousel-style--3,
  &.carousel-style--1a,
  &.carousel-style--1 {
    .carousel--item--content {
      order: 1;
      padding: 103px 118px 104px 118px;

      @media only screen and (max-width: $bs-md) {
        padding: 30px;
      }
    }

    picture {
      order: 2;
      
      @media only screen and (max-width: $bs-md) {
        height: 180px;
      }
    }

    .owl-prev, .owl-next {
      background: url(../img/icon/arrow-long--ffffff.svg) no-repeat 0 0 / contain !important;
    }
  }
  
  &.carousel-style--1a,
  &.carousel-style--1 {
    .owl-prev {
      left: 68px;
      
      @media only screen and (max-width: $bs-lg) {
        left: 34px;
      }
    }
    
    .owl-next {
      right: 68px;
      
      @media only screen and (max-width: $bs-lg) {
        right: 34px;
      }
    }
  }
  
  &.carousel-style--2 {
    margin: 0 px2vw(110px);

    @media only screen and (min-width: $container) {
      margin: 0 110px;
    }
    
    @media only screen and (max-width: $bs-md) {
      margin: auto;
    }
    
    .carousel--item--content {
      order: 2;
      background-color: #FFFEFA;
      padding: 109px 40px 40px 40px;

      @media only screen and (max-width: $bs-md) {
        padding: 20px;
      }

      & * {
        color: #333333;
      }
    }

    picture {
      order: 1;
      padding: 20%;
      
      @media only screen and (max-width: $bs-md) {
        padding-bottom: 67%;
      }
    }
    
    .owl-prev, .owl-next {
      background: url(../img/icon/arrow-long--c4c32f.svg) no-repeat 0 0 / contain !important;
      @media only screen and (max-width: $bs-md) {
        display: none;
      }
    }
    
    .owl-prev {
      left: -60px;
    }
    
    .owl-next {
      right: -60px;
    }
  }

  &.carousel-style--3 {
    padding-bottom: 55px;
    
    .owl-nav {
      position: absolute;
      bottom: -55px;
      left: 0;
      width: 100%;
      text-align: center;
    }
    
    .owl-prev, .owl-next {
      background: url(../img/icon/arrow-long--c4c32f.svg) no-repeat 0 0 / contain !important;
      position: relative;
    }

    .owl-prev {
      margin-right: 15px;
    }

    .owl-next {
      margin-left: 15px;
    }
  }
}

.carousel-style--1 {
  .carousel--item {
    &--title {
      font-family:"General Sans Bold";
      font-weight: 700;
      font-size: 140px;
      line-height: 1;

      @media only screen and (max-width: $bs-sm) {
        font-size: 80px;
      }
      
      span {
        font-family:"General Sans Regular";
        font-weight: 400;
        font-size: 40px;
        line-height: 1.25;

        @media only screen and (max-width: $bs-sm) {
          font-size: 28px;
        }
      }
    }

    &--text * {
      font-family:"General Sans Regular";
      font-weight: 400;
      font-size: 30px;
      line-height: 1.26;

      @media only screen and (max-width: $bs-sm) {
        font-size: 22px;
      }
    }
  }
}

.carousel-style--2 {
  .carousel--item {
    &--title {
			font-family:"General Sans Regular";
      font-weight: 400;
      font-size: 30px;
      line-height: 1;

      @media only screen and (max-width: $bs-sm) {
        font-size: 22px;
      }
    }
  }
}

.carousel-style--1a,
.carousel-style--3 {
  .carousel--item {
    &--title {
      font-family:"General Sans Regular";
      font-weight: 400;
      font-size: 40px;
      line-height: 1.1;

      @media only screen and (max-width: $bs-sm) {
        font-size: 28px;
      }
      
      span {
        font-family:"General Sans Regular";
        font-weight: 400;
        font-size: 40px;
        line-height: 1.25;

        @media only screen and (max-width: $bs-sm) {
          font-size: 28px;
        }
      }
    }
  }
}
