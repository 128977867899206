form {
	.form--small-text {
		font-family:"Gotham-Book";
		font-size: 14px;
		line-height: 1.29;
	}
}

.form-group {
	margin-bottom: 20px;
}

.pretty-input, .pretty-textarea, .pretty-select {
	label {
		display: block;
		margin-bottom: 20px;
		font-family:"Gotham-Book";
		font-weight: 400;
		font-size: 19px;
		line-height: 1.26;
		color: var(--body-text)!important;
	}

	&.field-required {
		label {
			position: relative;
			&:after {
				content: "*";
				margin-left: 5px;
				color: #ff0000;
			}
		}
	}
}

.field-required-symbol {
	&:after {
		content: "*";
		margin-left: 5px;
		color: #ff0000;
	}
}

.pretty-input {
	.show-input-value {
		cursor: pointer;
		background-repeat: no-repeat;
		background-position: center center;
		width: 20px;
		height: 20px;
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translate(0,-50%);
	}

	input[type="password"] + .show-input-value {
		background-image: url(../img/icon/icon-eye-close.svg);
	}

	input[type="text"] + .show-input-value {
		background-image: url(../img/icon/icon-eye-open.svg);
	}
}

.pretty-input--autocomplete {
	position: relative;

	ul {
		position: absolute;
		z-index: 2;
		width: 100%;
		margin: 0;
		padding: 0;
		background-color: #ffffff;

		li {
			cursor: pointer;
			&:before { display: none; }
			span {
				display: block;
				padding: 10px 20px;

				&:hover {
					color: #ffffff;
					background-color: var(--dark-grey);
				}
			}
		}
	}
}

input, textarea {
	font-family:"Gotham-Book";
	font-weight: 400;
	font-size: 14px;
	line-height: 1.29;
	border: 1px solid var(--dtm-light-green);
	background-color: var(--dtm-cream);
	width: 100%;
	padding: 12.5px 20px;
}

.pretty-radio, .pretty-checkbox {
	position: relative;
	padding-left: calc(20px + 10px);
	line-height: 20px;
	display: inline-block;

	input {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 25px;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}

	div {
		border: 1px solid #000000;
		width: 20px;
		height: 20px;
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
	}

	input + div {
		&:after {
			content: "";
			display: block;
			width: 90%;
			height: 90%;
			background-color: #000000;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			visibility: hidden;
		}
	}

	label {
		margin-bottom: 0;
		font-family:"Gotham-Book";
		font-weight: 350;
		font-size: 19px;
		line-height: 1.26;

		@media only screen and (max-width: $bs-xs) {
			font-size: 17px;
		}
	}

	&._dtm-light-green {
		div { border-color: var(--dtm-light-green); }
		input:checked + div {
			&:before, &:after {
				visibility: visible;
				background-color: var(--dtm-light-green);
			}
		}
	}
}

.error {
	&--show {
		opacity: 1!important;
		pointer-events: visible!important;
	}

	.msg--error {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		font-size: 14px;
		line-height: 1.29;
		padding: 12.5px 20px;
		text-indent: 30px;
		color: #ff0000;
		border: 1px solid var(--error);
		opacity: 0;
		pointer-events: none;

		&:before, &:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
		}

		&:before {
			// error message background cannot be in opacity, or it would reveal the text in the inputbox
			// background-color: var(--error);
			// opacity: 0.1;
			background-color: #f6e1d5;
		}

		&:after {
			background-image: url(../img/icon/alert-DC0000.svg);
			background-repeat: no-repeat;
			background-position: left 20px center;
		}
	}

	.msg--error, .error + .msg--error {
		opacity: 1;
		pointer-events: auto;
	}

	.pretty-input {
		&:before {
			&:before {
				visibility: visible;
				background-color: var(--error);
				opacity: 0.1;
			}
		}
	}

	.pretty-radio, .pretty-checkbox {
		div { border-color: var(--error); }
		input + div {
			&:before, &:after {
				visibility: visible;
				background-color: var(--error);
				opacity: 0.1;
			}
		}
	}
}

.pretty-radio {
	div { border-radius: 50%; }

	input:checked + div {
		&:after { border-radius: 50%; }
	}
}

// .pretty-checkbox {
	// input + div {
		// &, &:after {
			// border-radius: 50%;
		// }
	// }
// }

.pretty-select {
	&.field-required {
		position: relative;
		&:after {
			content: "*";
			margin-left: 5px;
			color: #ff0000;
			position: absolute;
			left: 0;
			top: 10px;
		}
	}

	select {
		width: 100%!important;
		height: 45px!important;
		border: 1px solid!important;
		border-radius: 0!important;
		background: none!important;
		padding: 0 20px!important;
		font-family:"Gotham-Book"!important;
		font-weight: 400!important;
		font-size: 14px!important;
		line-height: 1.29!important;
		background-repeat: no-repeat!important;
		background-position: center right 20px!important;
		background-size: 20px auto!important;
		appearance: none!important;
		background-image: url(../img/icon/arrow-EB6723.svg)!important;
	}

	&._dtm-cream {
		select {
			border-color: var(--dtm-light-green)!important;
			background-color: var(--dtm-cream)!important;
			color: #303030!important;
		}
	}
}

.error {
	.pretty-select select {
		border-color: #ff0000!important;
	}
}

.pretty-upload-button {
	label, div { display: none; }
	position: relative;
	border: 1px solid var(--dtm-light-green);
	background-image: url(../img/placeholder--upload.svg);
	background-repeat: no-repeat;
	background-size: 50% auto;
	background-position: center;
	padding-bottom: 100%;

	&.plain-text-layout {
		background: none;
		border: 0;
		padding-bottom: 0;

		label, div {
			display: block;
		}

		.pretty-upload-button--clear {
			position: relative;
			top: auto; right: auto;
		}
	}

	&.no-image {
		background: none;
	}

	&--clear {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		background: none;
		border: 0;
		padding: 10px;
	}

	&, input {
		cursor: pointer;
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	picture {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

.pretty-textarea {
	.msg--error {
		&:after {
			background-position: left 20px top 12.5px;
		}
	}
}

.fancy-field--file {
	position: relative;

	div {
		font-size: 14px;
		line-height: 1.28;

		@media only screen and (max-width: $bs-sm) {
			font-size: 14px;
		}
	}

	span {
		font-family:"Gotham-Bold";
		font-weight: 500;
		font-size: 16px;
		line-height: 1.1;
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	span {
		color: #EB6723;
		border-bottom: 1px solid #FDC422;
		display: inline-block;
		padding-bottom: 3px;
		cursor: pointer;
	}

	.fancy-field--file--filename-complex {
		position: relative;
		z-index: 3;

		.fancy-field--file--remove-file-btn {
			span {
				display: none;
			}

			position: relative;
			top: 2px;
			width: 13px;
			height: 13px;
			padding: 10px;
			cursor: pointer;

			&:before, &:after {
				content: "";
				display: block;
				width: 13px;
				height: 1px;
				background-color: #EB6723;
				transform-origin: center center;
				position: absolute;
				top: 50%;
				left: 50%;
			}

			&:before {
				transform: translate(-50%, -50%) rotate(45deg);
			}

			&:after {
				transform: translate(-50%, -50%) rotate(-45deg);
			}
		}
	}
}

