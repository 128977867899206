.home--hero {
	margin: 50px 0;

	@media only screen and (max-width: $bs-sm) {
		margin: 20px 0;
	}

	h1 {
		font-size: px2vw(60px);
		margin-bottom: px2vw(30px);

		@media only screen and (min-width: $container) {
			font-size: 60px;
			margin-bottom: 30px;
		}

		@media only screen and (max-width: $bs-xs) {
			font-size: 40px;
			margin-bottom: 20px;
		}
	}

	&-text {
		order: 1;
		max-width: 530px;
		width: calc(530px / $container * 100%);
		margin-right: px2vw(20px);
		font-family:"Gotham-Book";
		font-weight: 350;
		font-size: px2vw(19px);
		line-height: 1.26;

		@media only screen and (min-width: $container) {
			margin-right: 20px;
			font-size: 19px;
		}

		@media only screen and (max-width: $bs-sm) {
			margin-right: 0;
		}
		
		@media only screen and (max-width: $bs-xs) {
			max-width: none;
			width: 100%;
			font-size: 16px;
		}

		& * {
			margin-bottom: px2vw(30px);

			@media only screen and (min-width: $container) {
				margin-bottom: 30px;
			}

			@media only screen and (max-width: $bs-xs) {
				margin-bottom: 20px;
			}
		}

		footer {
			margin-top: 48px;

			@media only screen and (max-width: $bs-xs) {
				margin-top: 20px;
			}
		}
	}

	&-animation {
		max-width: calc(795px / $container * 100%);
		width: 795px;
		order: 2;

		@media only screen and (max-width: $bs-xs) {
			max-width: none;
			width: 100%;
			margin-bottom: 20px;
		}
		
		.figure {
			width: 100%;
			padding-bottom: 100%;
			position: relative;
			margin: auto;

			&-rolling {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				svg {
					width: 100%;
					height: 100%;
					animation: rolling 20s infinite linear;

					path {
						transition: fill 2s ease;
					}
				}
			}

			&-static {
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					padding: 10.9%;
					opacity: 0;
					transition: opacity 0.5s ease-in-out;

					&.active {
						opacity: 1;
					}
					
					@media only screen and (min-width: $container) {
						padding: 83px;
					}
				}
			}
		}

		@keyframes rolling {
			from {
				transform: rotate(0deg);
			}

			to {
				transform: rotate(360deg);
			}
		}
	}
}

.home--hero-animation {
	.cls-1 {
		fill: #c5c500;
	}
}
