.profile-edit--catalogue {
	max-width: calc(1080px + $padding-left + $padding-right);

	.profile {
		grid-column-gap: calc(130em / 18);
	}
}

.profile--my-catalogue {
	.tabbed-content {
		position: relative;

		ul.ui-tabs-nav {
			li:before { display: none; }

			a {
				display: block;
				text-align: center;
				padding: 25px 20px;
				text-decoration: none !important;
				color: var(--body-text);

				h5 { margin-bottom: 0; }
			}
		}

		&--content--background-color {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
		}
	}

	.accordion {
		.h3, h3 {
			margin-bottom: 0;
			font-family:"General Sans Medium";
			font-weight: 600;
			font-size: 18px;
		}
	}
}

$myCatalogueContainer: 690px;
.profile--my-catalogue {
	.product-listing--item {
		max-width: 220px;
		width: calc(220px / $myCatalogueContainer * 100%);
		margin-right: calc(15px / $myCatalogueContainer * 100%);
		margin-bottom: 30px;

		&:nth-child(3n) {
			margin-right: 0;
		}

		@media only screen and (max-width: $bs-xs) {
			max-width: none;
			width: 100%;
			margin-right: 0!important;
		}
	}
}

.profile--my-catalogue {
	.profile--live-catalogue,
	.profile--acquired-catalogue {
		padding: 30px;
	}
}

