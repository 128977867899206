.widget--user-long {
	margin-bottom: 30px!important;

	@meida only screen and (max-width: $bs-xs) {
		margin-bottom: 15px!important;
	}

	picture {
		order: 1!important;
		max-width: 50px!important;
		width: px2vw(50px)!important;
		height: px2vw(50px)!important;
		margin-right: px2vw(15px)!important;
		margin-bottom: 0!important;
		padding-top: 0!important;

		@media only screen and (min-width: $container) {
			width: 50px!important;
			height: 50px!important;
			margin-right: 15px!important;
		}

		@media only screen and (max-width: $bs-xs) {
			width: px2vw(100px);
			height: px2vw(100px);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
	}

	main {
		order: 2!important;
	}

	&--username {
		font-family:"Gotham-Bold"!important;
		font-weight: 500!important;
		font-size: px2vw(18px)!important;
		line-height: 1.1!important;
		margin-top: px2vw(10px)!important;
		// width: px2vw(172px)!important;
		word-wrap: anywhere!important;
		// text-overflow: ellipsis!important;
		// overflow: hidden!important;

		@media only screen and (min-width: $container) {
			font-size: 18px!important;
			margin-top: 10px!important;
			width: 172px!important;
		}

		@media only screen and (max-width: $bs-xs) {
			font-size: 15px!important;
		}
	}

	&--has--catalogue-link {
		position: relative!important;
		border: 1px solid #303030!important;
		padding: px2vw(20px) px2vw(48px) px2vw(39px) px2vw(48px)!important;

		@media only screen and (min-width: $container) {
			padding: 20px 48px 39px 48px!important;
		}

		@media only screen and (max-width: $bs-xs) {
			padding: 20px!important;
		}
	}

	&--float-panel {
		position: absolute!important;
		bottom: px2vw(-21px)!important;
		left: 50%!important;
		transform: translate(-50%,0%)!important;

		@media only screen and (min-width: $container) {
			bottom: -21px!important;
		}

		@media only screen and (max-width: $bs-xs) {
			bottom: -20%!important;
		}
	}

	&--chat-btn, &--catalogue-link {
		font-family: Gotham-Bold !important;
		font-weight: 500 !important;
		line-height: 1 !important;
		background-color: #303030 !important;
		color: #FFFEFA !important;
		text-decoration: none!important;
		text-transform: uppercase!important;
		white-space: nowrap!important;
		font-size: px2vw(16px)!important;
		padding: px2vw(15px) px2vw(13.5px) px2vw(11px)!important;

		@media only screen and (min-width: $container) {
			font-size: 16px!important;
			padding: 15px 13.5px 11px!important;
		}

		@media only screen and (max-width: $bs-xs) {
			font-size: px2vw(32px)!important;
		}
	}

	&--catalogue-link + &--chat-btn {
		margin-left: px2vw(27px);

		@media only screen and (min-width: $container) {
			margin-left: 27px;
		}
	}
}

