.header--user-navigation {
	position: relative;
	padding: 0 px2vw(15px);

	@media only screen and (min-width: $container) {
		padding: 0 15px;
	}

	ul {
		opacity: 0;
		pointer-events: none;

		li:before { display: none; }
	}

	&.active {
		ul {
			opacity: 1;
			pointer-events: auto;
		}
	}

	button {
		text-transform: uppercase;
		background: none;
		border: 0;
		padding: 0;
		font-family:"General Sans Medium";
		font-weight: 500;
		font-size: 17px;
		line-height: 1.1;
		padding: px2vw(10px);
		border-radius: 50%;
		position: relative;
		height: 60px;
		width: 60px;
		position: relative;

		@media only screen and (min-width: $container) {
			padding: 10px;
		}

		&:before {
			content: "";
			background-color: var(--dtm-light-green);
			opacity: 0.3;
			border-radius: 50%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
		}

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 70%;
			height: 70%;
			transform: translate(-50%,-50%);
			border-radius: 50%;
			object-fit: cover;
		}
	}

	ul {
		list-style: none;
		padding-left: 0;
		position: absolute;
		// top: 30px;
		left: 50%;
		z-index: 2;
		transform: translate(-50%,0);
		// width: 100%;
		width: 180px;
		margin-top: 20px;
		margin-bottom: 0;
		background-color: #EDECBD;
		padding: px2vw(15px) px2vw(20px);

		@media only screen and (min-width: $container) {
			padding: 15px 20px;
		}

		li {
			line-height: 1;
		}

		a {
			font-family:"General Sans Regular";
			font-weight: 400;
			padding: 7.5px 0!important;
			display: block;
			color: var(--dtm-body-text);
			white-space: break-spaces;

			@media only screen and (min-width: $container) {
				padding: 7.5px 0;
			}

			&.active, &:hover {
				font-family:"General Sans Medium";
				font-weight: 500;
			}

			&.header--lang-switch--item-highlight {
				color: var(--dtm-orange);
			}
		}
	}
}

