.modal-box {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	background-color: rgba(196, 195, 47, 0.5);
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.5s ease-in-out;

	&.modal-box--show {
		opacity: 1!important;
		pointer-events: auto!important;
	}

	&--wrapper {
		max-width: calc(860px + 110px + 110px);
		width: 90%;
		background-color: var(--dtm-cream);
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		padding: px2vw(100px) px2vw(110px);

		@media only screen and (min-width: $container) {
			padding: 100px 110px;
		}
	}

	&--generic {
		text-align: left;
	}

	.modal-box--wrapper.modal-box--generic {
		max-width: 860px;
	}

	h2 {
		font-family:"General Sans Regular";
		font-weight: 400;
		font-size: 40px;
		line-height: 1.25;
		margin-bottom: 60px;
		color: var(--body-text);
		white-space: break-spaces;

		@media only screen and (max-width: $bs-xs) {
			font-size: 28px;
			margin-bottom: 30px;
		}
	}

	&--close-btn {
		span { display: none!important; }

		position: absolute;
		top: px2vw(50px);
		right: px2vw(50px);
		width: px2vw(26px);
		height: px2vw(26px);
		background: none;
		border: 0;
		padding: 0;
		background-image: url(../img/icon/cross-EB6723.svg);
		background-size: contain;
		background-repeat: no-repeat;
		display: inline-block;

		@media only screen and (min-width: $container) {
			top: 50px;
			right: 50px;
			width: 26px;
			height: 26px;
		}

		@media only screen and (max-width: $bs-xs) {
			top: px2vw(25px);
			right: px2vw(25px);
		}
	}
}
