.contact-us.page {
  .page-header .page-title {
    width: 100%;
    text-align: center;
  }

  .contact--intro, .contact--info {
    text-align: center;

    .container {
      max-width: 639px;
    }
  }

  .contact--info {
    &--email {
      a {
        display: inline-block;
        color: #178043;
        border-bottom: 2px solid #C4C32F;
        padding-bottom: 10px;
        margin-bottom: 30px;
        text-decoration: none!important;
      }
    }

    &--social-media {
      margin-bottom: 60px;
      
      @media only screen and (max-width: $bs-xs) {
        margin-bottom: 50px;
      }
      
      h5 {
        text-align: center;
        margin-bottom: 20px;
      }

      a {
        display: inline-block;
        margin-right: 10px;
        max-width: 30px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.contact--form {
  .container {
    max-width: 639px;
  }
}

