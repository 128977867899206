.star-rating {
	&--star {
		display: inline-block!important;
		background-repeat: no-repeat!important;
		background-size: contain!important;
		// width: px2vw(14px)!important;
		// height: px2vw(15px)!important;
		// for some reasons, media query (min-width) below not working
		width: 14px!important;
		height: 15px!important;
		margin-right: px2vw(3px)!important;

		@meida only screen and (min-width: $container) {
			width: 14px!important;
			height: 15px!important;
			margin-right: 3px!important;
		}

		@media only screen and (max-width: $bs-xs) {
			width: 15px!important;
			height: 15px!important;
			margin-right: 3px!important;
		}

	}

	&--star--alt {
		cursor: pointer!important;
		background-image: url(../img/icon/rating-star--alt.svg)!important;
	}

	&--star--selected {
		background-image: url(../img/icon/rating-star.svg)!important;
	}
}
