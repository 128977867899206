.accordion {
	h3, .ui-accordion-header {
		cursor: pointer;
		width: 100%;
		// padding: 20px 30px 25px 30px;
		position: relative;
		text-align: left;
		font-family:"General Sans Regular";
		font-weight: 700;
		font-size: 20px;
		line-height: 1.35;

		@media only screen and (max-width: $bs-sm) {
			font-size: 18px;
		}

		&:after {
			content: "+";
			font-family:"General Sans Medium";
			font-weight: 600;
			position: absolute;
			right: 30px;
		}

		&.ui-state-active {
			&:after { content: "-"; }
		}

		&.accordion--arrow {
			&:after {
				content: "";
				background-image: url(../img/icon/arrow-EB6723.svg);
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				width: 13px;
				height: 6px;
				display: inline-block;
				position: absolute;
				right: 0;
				bottom: 50%;
				transform: translate(0, -50%);
				transform-origin: center center;
			}

			&.ui-state-active {
				&:after { transform: translate(0, -50%) rotate(180deg); }
			}
		}
	}

	// styling below is for the custom accordion that is built with VueJS
	// which is not in use anymore
	// h3 + div {
		// max-height: 0;
		// overflow: hidden;
		// transition: max-height 1s ease-in-out 0s;
	// }

	// h3.open {
		// &:after {
			// content: "-";
		// }

		// & + div {
			// transition: max-height 5s ease-in-out 0.5s!important;
			// max-height: 9999px; // set this to a large number, it will NOT make the height of the content div too tall
		// }
	// }

}
