.purchase-history {
	position: relative;

	.loading-wheel {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

  &__filter {
    margin-bottom: calc(15em / 18);

    button {
      margin-right: calc(15em / 18);

			@media only screen and (max-width: $bs-xs) {
				margin-bottom: 20px;
			}

      opacity: .5;

      label {
        cursor: pointer;
      }

      &.active {
        opacity: 1;
      }
    }
  }

  &__listing {

  }

  &__pagination {

  }
}

.deal {
  &-main {
    padding: calc(30em / 18);
    background-color: var(--dtm-cream);
    display: grid;
    grid-template-columns: calc(160em / 18) 2fr 1fr;
    grid-column-gap: calc(30em / 18);
    grid-row-gap: calc(20em / 18);

    @media only screen and (max-width: $bs-sm) {
      display: block;
    }

    &__img {
      grid-column: 1 / 2;
      grid-row: 1 / 4;

      @media only screen and (max-width: $bs-sm) {
        text-align: center;
        margin-bottom: calc(20em / 15);
      }
    }

    &__user {
      grid-column: span 2;
      border-bottom: 1px solid var(--dtm-light-green);
      display: grid;
			grid-template-columns: calc(50em / 18) 1fr auto;
      grid-template-rows: repeat(2, minmax(0, 1fr));
      grid-column-gap: calc(15em / 18);
      height: calc(65em / 18);
      padding-bottom: calc(15em / 18);

      @media only screen and (max-width: $bs-xs) {
        margin-bottom: calc(20em / 15);
				grid-template-columns: calc(50em / 18) 1fr;
				grid-template-rows: repeat(2, minmax(0, 1fr)) auto;
				height: auto;
      }

      &-avatar {
        border-radius: 50%;
        overflow: hidden;
        grid-row: span 2;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
      }

			&-chat {
				grid-row: 1 /3;
				grid-column: 3 / 4;
				align-self: center;

				@media only screen and (max-width: $bs-xs) {
					grid-row: 3 / 4;
					grid-column: 1 / 3;
					margin-top: 20px;
					text-align: center;
				}
			}

      &-star {

      }

      &-name {
        font-family: "Gotham-Bold";
				font-weight: 500;

      }
    }

    &__desc {
      grid-column: span 2;
      font-family: "Gotham-Book";
    }

    &__type {
      @media only screen and (max-width: $bs-sm) {
        text-align: center;
      }

			.product-tag {
				@media only screen and (max-width: $bs-sm) {
					margin: 20px 0;
				}
			}
    }

    &__amount {
      text-align: right;
      font-family:"General Sans Medium";
			font-weight: 600;
      font-size: calc(20em / 18);

      @media only screen and (max-width: $bs-sm) {
        text-align: center;
      }
    }
  }

  &-detail {
    background-color: rgba(253, 196, 34, 0.2);

    &__btn {
      height: calc(40em / 14);
      border: none;
      background-color: transparent;
      font-family: "Gotham-Bold";
			font-weight: 500;
      padding-left: calc(30em / 14);
      padding-right: calc(30em / 14);
      font-size: calc(14em / 18);
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      img {
        transition: .3s;
      }

      &--active {
        img {
          transform: rotate(180deg);
        }
      }
    }

    &__collapse {
      padding-left: calc(30em / 18);
      padding-right: calc(30em / 18);
    }

    &__content {
      border-top: 1px solid rgba(235, 103, 35, 0.5);

    }

    &__row {
      border-bottom: 1px solid rgba(235, 103, 35, 0.2);
      height: calc(40em / 18);
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:last-child {
        border-bottom: 0;
      }
    }

    &__col {
      font-size: calc(14em / 18);
    }
  }

  &-info {
    display: grid;
    grid-template-columns: calc(250em / 18) calc(285em / 18) auto;
    height: calc(40em / 18);

    @media only screen and (max-width: $bs-sm) {
      grid-template-columns: 100%;
      grid-auto-rows: calc(40em / 15);
      height: auto;
    }

    &__date {
      background-color: rgba(196, 195, 47, .2);
      padding-left: calc(30em / 14);
      display: flex;
      align-items: center;
      font-size: calc(14em / 18);
    }

    &__rate {
      padding-left: calc(30em / 18);
      display: flex;
      font-family: "Gotham-Bold";
			font-weight: 500;
      align-items: center;

      .star-rating {
        margin-left: calc(10em / 18);
      }
    }

    &__report {
      > button {
        background-color: #7D7D7D;
        width: 100%;
        height: 100%;
        border: none;
        color: #fff;
        font-size: calc(16em / 18);
        font-family: "Gotham-Bold";
				font-weight: 500;
      }
    }
  }

  &--buy {
    .deal {
      &-info {
        &__rate {
          background-color: var(--dtm-yellow);
        }
      }
    }
  }

  &--swap {
    .deal {
      &-info {
        &__rate {
          background-color: var(--dtm-light-green);
        }
      }
    }
  }
}

.purchase-history--order-item {
  margin-bottom: calc(30em / 18);
	
	.deal {
		margin-bottom: 0;

		.deal-main__user, .deal-info {
			display: none;
		}

		&:first-of-type {
			.deal-main__user {
				display: grid;
			}
		}

		&:last-of-type {
			.deal-info {
				display: grid;
			}
		}
	}

	&.swap {
		.deal-info__rate {
			background-color: var(--dtm-light-green);

			.star-rating--star--alt {
				background-image: url(../img/icon/rating-star--dtm-green--alt.svg)!important;
			}
			.star-rating--star--selected {
				background-image: url(../img/icon/rating-star--dtm-green.svg)!important;
			}
		}
	}

	&.sell {
		.deal-info__rate {
			background-color: var(--dtm-yellow);

			.star-rating--star--alt {
				background-image: url(../img/icon/rating-star--dtm-orange--alt.svg)!important;
			}
			.star-rating--star--selected {
				background-image: url(../img/icon/rating-star--dtm-orange.svg)!important;
			}
		}
	}
}

.report{
  &-form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: calc(20em / 18);

    @media only screen and (max-width: $bs-sm) {
      grid-template-columns: 100%;
    }

    .wide-field {
			grid-column: span 2;

			@media only screen and (max-width: $bs-sm) {
				grid-column: span 1;
			}

      textarea {
        height: calc(135em / 18);
      }
    }

    &__actions {
      grid-column: span 2;
      text-align: center;

      @media only screen and (max-width: $bs-sm) {
        grid-column: span 1;
      }
    }
  }

  &-thanks {
    &__title {
      text-align: center;
      font-size: calc(50em / 18);
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: calc(630em / 50);
    }

    &__desc {

    }
  }
}
