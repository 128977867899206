.product-listing--item {
	position: relative;
	padding-bottom: px2vw(65px);

	@media only screen and (min-width: $container) {
		padding-bottom: 65px;
	}

	@media only screen and (max-width: $bs-sm) {
		padding-bottom: 5vw;
	}

	@media only screen and (max-width: $bs-xs) {
		padding-bottom: 95px;
	}

	a { text-decoration: none!important; color: inherit!important; }

	position: relative;
	padding-top: px2vw(15px);

	@media only screen and (min-width: $container) {
		padding-top: 15px;
	}

	@media only screen and (max-width: $bs-xs) {
		padding-top: 15px;
	}

	picture {
		display: block;
		position: relative;
		margin-bottom: px2vw(30px);
		padding-bottom: 100%;

		@media only screen and (min-width: $container) {
			margin-bottom: 30px;
		}

		@media only screen and (max-width: $bs-xs) {
			margin-bottom: 35px;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.product-tag--special-label {
		position: absolute;
		bottom: px2vw(-15px);
		right: px2vw(20px);
		z-index: 1;

		@media only screen and (min-width: $container) {
			bottom: -15px;
			right: 20px;
		}

		@media only screen and (max-width: $bs-xs) {
			bottom: -15px;
			right: 20px;
		}
	}

	.product-tag--type {
		position: absolute;
		top: 0;
		left: px2vw(10px);
		z-index: 1;

		@media only screen and (min-width: $container) {
			left: 10px;
		}
	}

	&.product-listing--item--alt {
		.product-tag--type {
			top: auto;
			left: auto;
			bottom: px2vw(-15px);
			right: px2vw(10px);

			@media only screen and (min-width: $container) {
				bottom: -15px;
				right: 10px;
			}

			@media only screen and (max-width: $bs-xs) {
				bottom: -15px;
				right: 10px;
			}
		}
	}

	&--star-button {
		span { display: none; }

		background: #FFFEFA;
		border-radius: 50%;
		width: px2vw(30px);
		height: px2vw(30px);
		border: 0;
		padding: 0;
		position: absolute;
		bottom: px2vw(10px);
		left: px2vw(10px);
		z-index: 1;

		@media only screen and (min-width: $container) {
			width: 30px;
			height: 30px;
			bottom: 10px;
			left: 10px;
		}

		img {
			position: absolute!important;
			top: 50%!important;
			left: 50%!important;
			transform: translate(-50%,-50%)!important;
			width: 50%!important;
		}
	}

	h5 {
		font-family:"General Sans Medium";
		font-weight: 600;
		font-size: px2vw(20px);
		line-height: 1.35;
		margin-bottom: px2vw(20px);

		@media only screen and (min-width: $container) {
			font-size: 20px;
			margin-bottom: 20px;
		}

		@media only screen and (max-width: $bs-xs) {
			font-size: 18px;
			margin-bottom: 15px;
		}
	}

	h6 {
		font-family:"Gotham-Medium";
		font-weight: 500;
		font-size: px2vw(19px);
		line-height: 1.26;
		color: var(--dtm-green);
		margin-bottom: px2vw(15px);
		text-transform: uppercase;

		@media only screen and (min-width: $container) {
			font-size: 19px;
			margin-bottom: 15px;
		}

		@media only screen and (max-width: $bs-xs) {
			font-size: 17px;
			margin-bottom: 11px;
		}
	}

	&--size, &--price {
		font-family:"Gotham-Book";
		font-weight: 700;
		font-size: px2vw(20px);
		line-height: 1.35;

		@media only screen and (min-width: $container) {
			font-size: 20px;
		}

		@media only screen and (max-width: $bs-xs) {
			font-size: 16px;
		}
	}

	&--price {
		@media only screen and (max-width: $bs-xs) {
			margin-bottom: 10px;
			width: 100%;
		}
	}

	&--utility {
		border-top: 1px solid #303030;
		margin-top: px2vw(15px);
		padding-top: px2vw(8px);
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;

		@media only screen and (min-width: $container) {
			margin-top: 15px;
			padding-top: 8px;
		}

		@media only screen and (max-width: $bs-xs) {
			margin-top: 10px;
			padding-top: 10px;
		}
	}
}

.product-tag {
	background-repeat: no-repeat;
	padding: 0 0 0 px2vw(30px);
	display: inline-block;
	border: 0;
	background: none;
	background-size: cover;
	// background-position: left bottom;
	background-position: left center;

	@media only screen and (min-width: $container) {
		padding: 0 0 0 30px;
	}

	@media only screen and (max-width: $bs-xs) {
		padding: 0 0 0 30px;
	}

	label {
		font-family:"Gotham-Bold";
		font-weight: 500;
		font-size: px2vw(16px);
		line-height: 1;
		text-transform: uppercase;
		color: #FFFEFA;
		padding: px2vw(8px) px2vw(10px) px2vw(6px) 0;
		display: block;

		@media only screen and (min-width: $container) {
			font-size: 16px;
			padding: 8px 10px 6px 0;
		}

		@media only screen and (max-width: $bs-xs) {
			font-size: 16px;
			padding: 8px 10px 6px 0;
		}
	}
}

.product-tag--inactive {
	background-image: url(../img/icon/product-tag--CCCCCC.svg)!important;

	label {
		background-color: #CCCCCC!important;
	}
}

.product-swap-donation.product-tag--type,
.product-tag--special-label {
	background-image: url(../img/icon/product-tag--EB6723.svg);
	background-size: contain;

	label {
		background-color: #EB6723;
	}
}

.product-sell.product-tag--type {
	background-image: url(../img/icon/product-tag--FDC422.svg);

	label {
		background-color: #FDC422;
		padding-right: px2vw(24px); // this is to prevent the background color from overlapping the arrow, due to the label being too short

		@media only screen and (min-width: $container) {
			padding-right: 24px;
		}

		@media only screen and (max-width: $bs-xs) {
			padding-right: px2vw(84px);
		}
	}
}

.product-swap.product-tag--type {
	background-image: url(../img/icon/product-tag--C4C32F.svg);

	label {
		background-color: #C4C32F;
	}
}

.product-all.product-tag--type {
	background-image: url(../img/icon/product-tag--303030.svg);

	label {
		background-color: #303030;
	}
}
