.pagination {
	a {
		color: var(--body-text);
		text-decoration: none;
		margin-right: px2vw(30px);
		display: inline-block;

		@media only screen and (min-width: $container) {
			margin-right: 30px;
		}

		@media only screen and (max-width: $bs-xs) {
			margin-right: 30px;
		}

		&:last-child {
			margin-right: 0;
		}

		&:hover, &.active {
			font-weight: 700;
		}
	}

	.container {
		max-width: 95px!important;
		padding: 0;
	}

	&--arrow {
		span { display: none; }
		margin-top: 16px;

		&:after {
			content: "";
			display: inline-block;
			background-image: url(../img/icon/arrow-long--c4c32f.svg);
			background-repeat: no-repeat;
			background-position: center;
			width: 28px;
			height: 17px;
		}
	}

	&--arrow-prev {
		&:after {
			transform: rotate(180deg);
		}
	}
}
