.header--quick-search {
	margin: 0 px2vw(15px);

	@media only screen and (min-width: $container) {
		margin: 0 15px;
	}

	button {
		position:relative;
		background: none;
		border: 0;
		background-image: url(../img/icon/magnify-glass.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		width: px2vw(25px);
		height: 29px;

		@media only screen and (min-width: $container) {
			width: 25px;
		}

		@media only screen and (max-width: $bs-sm) {
			width: 30px;
		}

		&:hover {
			background-image: url(../img/icon/magnify-glass--C4C32F.svg);
		}

		label { display: none; }
	}

	// button {
		// background: none;
		// border: 0;
		// padding: 7px px2vw(15px);

		// @media only screen and (min-width: $container) {
			// padding: 0 15px;
		// }

		// @media only screen and (max-width: $bs-sm) {
			// padding: 0 7.5px;
		// }

		// img {
			// @media only screen and (max-width: $bs-sm) {
				// max-width: 20px;
			// }
		// }
	// }

	.quick-search-panel {
		position: absolute;
		left: 0;
		width: 100%;
		z-index: 2;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.5s ease-in-out;
		padding: 32px 0 35px 0;
		background-color: #FFFEFA;

		@media only screen and (max-width: $bs-sm) {
			top: 61px;
		}

		.container {
			max-width: 691px;
		}
	}

	&.open .quick-search-panel {
		opacity: 1;
		pointer-events: auto;
	}

	form {
		position: relative;

		input {
			border: 0;
			border-bottom: 1px solid #303030;
			border-radius: 0;
			padding-right: 35px;
		}

		button {
			position: absolute;
			right: 0;
			padding: 0;
		}
	}
}
