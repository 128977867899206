.module {
  $moduleFaqListingContainer: 1080px;
  &__faq-listing {
    .container {
      max-width: $moduleFaqListingContainer + $padding-left + $padding-right;
    }
    
    aside {
      max-width: 310px;
      width: calc(310px / $moduleFaqListingContainer * 100%);
      margin-right: calc(20px / $moduleFaqListingContainer * 100%);

      @media only screen and (max-width: $bs-xs) {
        margin-right: 0;
        margin-bottom: 33px;
        width: 100%;
      }
    }

    main {
      max-width: 750px;
      width: calc(750px / $moduleFaqListingContainer * 100%);
      
      @media only screen and (max-width: $bs-xs) {
        max-width: none;
        width: 100%;
      }
    }

		&__group {
			&-item {
				h3 {
					font-family:"Gotham-Bold";
					font-weight: 500;
					font-size: 18px;
					line-height: 1.27;
				}
			}
		}
  }

  &--instafeed {
    margin: 120px 0 calc(120px - 20px) 0;

    @media only screen and (max-width: $bs-md) {
      margin: 90px 0 calc(90px - 15px) 0;
    }

    aside {
      max-width: 421px;
      width: calc(421px / $container * 100%);
      margin-right: calc(19px / $container * 100%);
      
      @media only screen and (max-width: $bs-md) {
        max-width: none;
        width: 100%;
        margin-rigth: 0;
        margin-bottom: 30px;
      }

      h2 {
        text-transform: uppercase;
        padding-right: 50px;
				font-size: px2vw(60px);

				@media only screen and (min-width: $container) {
					font-size: 60px;
				}

				@media only screen and (max-width: $bs-sm) {
					font-size: 35px;
				}
      }
    }

    main {
      max-width: 860px;
      width: calc(860px / $container * 100%);
      
      @media only screen and (max-width: $bs-md) {
        max-width: none;
        width: 100%;
      }
    }


    &--item {
      max-width: 200px;
      width: calc(200px / 860px * 100%);
      margin-right: calc(20px / 860px * 100%);
      margin-bottom: 29px;

      &:nth-child(4n) {
        margin-right: 0;
      }

      @media only screen and (max-width: $bs-md) {
        max-width: none;
        width: 48%;
        margin-right: 2%!important;
        margin-bottom: 15px;
      }
      
      picture {
        display: block;
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

.module {
  &__organisation-listing,
  &__shop-listing {
    margin-bottom: 127px;
    
    form {
      margin-bottom: 60px;

      @media only screen and (max-width: $bs-xs) {
        margin-bottom: 50px;
      }

      .form-group {
        @media only screen and (max-width: $bs-md) {
          margin-bottom: 20px;
        }
      }
      
      // button[type="submit"] {
      //   background: none;
      //   border: 0;
      //   margin-right: 20px;
      // }
      
      input[name="keyword"] {
				background-image: url(../img/icon/magnify-glass--EB6723.svg);
        background-repeat: no-repeat;
        background-position: left 20px center;
        background-size: 21px 21px;
        padding-left: 51px;

        @media only screen and (max-width: $bs-xs) {
          background-size: 13px 13px;
        }
      }

      .selectboxit-container {
        max-width: 200px;
        width: 100%;
        
        @media only screen and (max-width: $bs-lg) {
          max-width: none;
        }
      }
    }
  }

  &__shop-listing {
    .shop-listing__item {
      max-width: 310px;
      width: calc(310px / $container * 100%);
      margin-right: calc(20px / $container * 100%);
      margin-bottom: 20px;
      border: 1px solid #7EDBD5;
      cursor: pointer;

      &:nth-child(4n) {
        margin-right: 0;
      }

      @media only screen and (max-width: $bs-sm) {
        max-width: none;
        width: 49%;
        margin-right: 2%!important;

        &:nth-child(2n) {
          margin-right: 0!important;
        }
      }

      @media only screen and (max-width: $bs-xs) {
        width: 100%;
        margin-right: 0!important;
      }

      .shop-item--price-label {
        color: #7EDBD5;
        margin-bottom: 10px;
        min-height: 24px;
      }

      &--types, &--district {
        color: #303030;
      }

      &--types {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;

        @media only screen and (max-width: $bs-xs) {
          height: auto;
        }
      }

      &--district {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &--learn-more {
        margin-top: 30px;
        position: absolute!important;
        bottom: 30px;
        left: 30px;
        
        @media only screen and (max-width: $bs-xs) {
          left: 20px;
          bottom: 20px;
          margin-top: 28px;
        }
      }

      &--header {
        padding: 30px 30px 60px 30px;
        
        @media only screen and (max-width: $bs-xs) {
          padding: 20px 20px 40px 20px;
        }
      }

      &--footer {
        background-color: rgba(126, 219, 213, 0.1);
        padding: 30px 30px 80px 30px;
        height: 147px;
        position: relative;
        
        @media only screen and (max-width: $bs-xs) {
          padding: 20px 20px 78px 20px;
          height: auto;
        }

        &-wrapper {
          height: 36px;
        }
      }
    }

    $shopListingPopupCardContainer: 1300px;
    .shop-listing--popup-card {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s ease-in-out;

      &__wrapper {
        background-color: rgba(242, 251, 246, 1);
        padding: 132px 164px;
        max-width: $shopListingPopupCardContainer;
        width: 100%;
        margin: 0 auto;
        position: relative;
        overflow: auto;
        bottom: -100%;
        transition: bottom 0.5s ease-in-out;
        
        @media only screen and (max-width: $bs-md) {
          padding: 71px 20px 25px 20px;
        }
      }
      
      &.open {
        opacity: 1;
        pointer-events: auto;

        .shop-listing--popup-card__wrapper {
          bottom: 0;
        }
      }
      
      &--close-button {
        span {
          display: none;
        }

        width: 35px;
        height: 35px;
        position: relative;
        background: none;
        border: 0;
        position: absolute;
        top: 42px;
        left: 40px;

        @media only screen and (max-width: $bs-md) {
          top: 25px;
          left: 20px;
        }

        &:before, &:after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #7EDBD5;
          position: absolute;
          top: 50%;
          left: 50%;
          transform-origin: 0 0;
        }

        &:before {
          transform: rotate(45deg) translate(-50%, -50%);
        }

        &:after {
          transform: rotate(-45deg) translate(-50%, -50%);
        }
      }
      
      .orgainistion-item--price-label {
        color: rgba(126, 219, 213, 1);
      }

      aside {
        max-width: 476px;
        width: calc(476px / 972px * 100%);
        margin-right: calc(20px / 972px * 100%);
        position: relative;
        max-height: 520px;
        overflow: hidden;

        @media only screen and (max-width: $bs-md) {
          max-width: none;
          width: 100%;
          margin-right: 0;
        }

        .shop-listing--popup-card--gallery {
          &--loading-wheel {
            position: absolute;
            top: 0;
            left: 0;
            background-color: #f2fbf6;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
          }
          
          &--main {
            margin-bottom: 20px;
            margin-top: 30px;
            
            @media only screen and (max-width: $bs-md) {
              margin-top: 20px;
              padding-bottom: 60px;
            }

            .slick-slide {
              display: flex;
              justify-content: center;
              max-height: 330px;
              
              @media only screen and (max-width: $bs-xs) {
                max-height: 152px;
              }

              img {
                object-fit: contain;
              }
            }
            
            @media only screen and (max-width: $bs-md) { 
              .slick-slider {
                position: relative;
                padding-bottom: 54px;
              }
            }
          }

          &--nav {
            padding-bottom: 60px;

            @media only screen and (max-width: $bs-md) {
              display: none;
            }

            div {
              padding: 0 10px;

              .slick-slide {
                width: 145px;
                height: 80px;
                position: relative;
                padding: 0;
                margin-right: 20px;
                
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }

            .slick-slider {
              position: relative;
              padding-bottom: 54px;
            }
          }
        }

        .slick-arrow {
          position: absolute;
          bottom: 0;
          width: 30px;
          height: 17px;
          margin-top: 37px;
          background: none;
          border: 0;
          background-image: url(../img/icon/arrow-long--7edbd5.svg);
          background-repeat: no-repeat;
          background-size: contain;
          overflow: hidden;
          text-indent: -999px;

          &.slick-prev {
            left: 38%;
            transform: rotate(-180deg);
          }

          &.slick-next {
            right: 38%;
          }
        }
      }

      main {
        max-width: 476px;
        width: calc(476px / 972px * 100%);
        border-bottom: 1px solid rgba(126, 219, 213, 1);
        margin-top: 30px;

        @media only screen and (max-width: $bs-md) {
          max-width: none;
          width: 100%;
          margin-right: 0;
        }

        .accordion {
          h3 {
            border-top: 1px solid rgba(126, 219, 213, 1);
            padding-top: 20px;
            margin-top: 20px;

            &:first-child {
              margin-top: 0;
            }

            .ui-accordion-header-icon {

              &:before, &:after {
                background-color: #7EDBD5;
              }
            }
          }

          label {
            margin-right: 5px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &__organisation-listing {
    .organisation-listing__item {
      background-color: #ffffff;
      max-width: 310px;
      width: calc(310px / $container * 100%);
      margin-right: calc(20px / $container * 100%);
      margin-bottom: 20px;
      border: 1px solid rgba(235, 103, 35, 1);
      cursor: pointer;

      &:nth-child(4n) {
        margin-right: 0;
      }

      @media only screen and (max-width: $bs-sm) {
        max-width: none;
        width: 49%;
        margin-right: 2%!important;

        &:nth-child(2n) {
          margin-right: 0!important;
        }
      }

      @media only screen and (max-width: $bs-xs) {
        width: 100%;
        margin-right: 0!important;
      }

      &--types {
        color: #303030;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;

        @media only screen and (max-width: $bs-xs) {
          height: auto;
        }
      }

      &--read-more {
        margin-top: 30px;
        position: absolute!important;
        bottom: 30px;
        left: 30px;
        
        @media only screen and (max-width: $bs-xs) {
          left: 20px;
          bottom: 20px;
          margin-top: 28px;
        }
      }

      &--header {
        padding: 30px 30px 60px 30px;
        
        @media only screen and (max-width: $bs-xs) {
          padding: 20px 20px 40px 20px;
        }

        picture {
          display: block;
          text-align: center;
					max-height: 100px;
					position: relative;
					padding-bottom: 100%;

					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
        }
      }

      &--footer {
        background-color: rgba(235, 103, 35, 0.1);
        padding: 30px 30px 80px 30px;
        height: 200px;
        position: relative;
        
        @media only screen and (max-width: $bs-xs) {
          padding: 20px 20px 78px 20px;
          height: auto;
        }

        &-wrapper {
          height: 36px;
        }
      }
    }

    .organisation-listing--popup-card {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s ease-in-out;

      &--accordion-loading-wheel {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: #f8cab3;
      }

      &__wrapper {
        background-color: #f8cab3;
        padding: 202px 40px;
        width: 50%;
        height: 100%;
        margin: 0 auto;
        position: fixed;
        overflow: auto;
        right: -100%;
        transition: right 0.5s ease-in-out;
        
        @media only screen and (max-width: $bs-sm) {
          width: 100%;
        }
      }
     
      &.open {
        opacity: 1;
        pointer-events: auto;

        .organisation-listing--popup-card__wrapper {
          right: 0;
        }
      }
     
      &--close-button {
        span {
          display: none;
        }

        width: 35px;
        height: 35px;
        position: relative;
        background: none;
        border: 0;
        position: absolute;
        top: 42px;
        left: 40px;

        @media only screen and (max-width: $bs-md) {
          top: 25px;
          left: 20px;
        }

        &:before, &:after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #EB6723;
          position: absolute;
          top: 50%;
          left: 50%;
          transform-origin: 0 0;
        }

        &:before {
          transform: rotate(45deg) translate(-50%, -50%);
        }

        &:after {
          transform: rotate(-45deg) translate(-50%, -50%);
        }
      }

      .form-group {
        margin-bottom: 30px;
      }
      
      .accordion {
        h3 {
          background-color: #EB6723;
          padding: 20px;
          margin-top: 20px;

          // &:first-of-type {
          //   background: none;
          //   padding: 0;
          //
          //   & + div {
          //     display: block!important;
          //
          //     @media only screen and (max-width: $bs-sm) {
          //       display: none!important;
          //     }
          //   }
          // }

          .ui-accordion-header-icon {

            &:before, &:after {
              background-color: #303030;
            }
          }
        }

        label {
          margin-right: 5px;
          white-space: nowrap;
        }
      }
    }
  }
}

.organisation-listing,
.shop-listing {
	&--popup-card label,
	&--popup-card .accordion h3 + div label {
		font-family:"Gotham-Bold";
		font-weight: 500;
	}

	&--popup-card {
		.accordion {
			h3 {
				font-family: "General Sans Regular";
				font-weight: 700;
				font-size: 20px;
				line-height: 1.35;
			}
		}
	}

	&--popup-card .accordion h3 + div,
	&__item--types,
	&__item--district,
	&--popup-card .accordion h3 + div,
	&__item--types,
	&__item--district {
		font-size: 14px;
		line-height: 1.28;

		@media only screen and (max-width: $bs-sm) {
			font-size: 14px;
		}
	}
}

.shop-listing--popup-card--types,
.shop-listing--popup-card--district,
.shop-item--price-label {
  // font-family:"General Sans Regular";
  // font-weight: 700;
  font-family:"Gotham-Book";
  font-weight: 700;
  font-size: 19px;
  line-height: 1.26;

  @media only screen and (max-width: $bs-sm) {
    font-size: 17px;
  }
}

.module {
	$moduleImageGalleryContainer: $bs-xs;
  &__image-gallery {
    height: px2vw(795px);
    font-size: 1vw;
    margin-bottom: 120px;

    @media only screen and (min-width: $container) {
      height: 795px;
    }

    @media only screen and (max-width: $moduleImageGalleryContainer) {
      height: 132vw;
      margin-bottom: 90px;
    }

    &--item {
      position: absolute;

      .main-image, .alt-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity 0.5s ease-in-out;
      }

      h5 {
        margin-top: 20px;
        margin-bottom: 0;
				font-size: px2vw(20px);

				@media only screen and (min-width: $container) {
					font-size: 20px;
				}

        @media only screen and (max-width: $moduleImageGalleryContainer) {
          margin-top: 10px;
        }

				@media only screen and (max-width: $bs-xs) {
					// font-size: 18px;
					font-size: px2vw(36px);
				}
      }

      .alt-image {
        opacity: 0;
      }

      &:hover {
        .main-image {
          opacity: 0;
        }

        .alt-image {
          opacity: 1;
        }
      }

      picture {
        display: block;
        position: relative;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-01 {
        width: 23.847%; // 310px
        top: 30%; // 259px
        left: 0;

        picture {
          padding-bottom: 87.1%;
        }

        @media only screen and (max-width: $moduleImageGalleryContainer) {
          width: 30%;
          top: 25%;

          picture {
            padding-bottom: 87%;
          }

          h5 {
            padding-left: 13%;
          }
        }
      }

      &-02 {
        width: 14.74%; // 200px
        top: 0;
        left: 14.8%; // 200px

        picture {
          padding-bottom: 75%;
        }

        @media only screen and (max-width: $moduleImageGalleryContainer) {
          // width: 12.88%;
          width: 25.76%;

          picture {
            padding-bottom: 75.7%;
          }
        }
      }

      &-03 {
        width: 30.883%; // 420px
        top: 12.6%; // 98px
        left: 33.8%; // 440px

        picture {
          padding-bottom: 113.574%;
        }

        @media only screen and (max-width: $moduleImageGalleryContainer) {
          // width: 27.17%;
          width: 50%;
          left: auto;
          right: 0;

          picture {
            padding-bottom: 113.3%;
          }
        }
      }

      &-04 {
        width: 22.8%; // 310px
        top: 0;
        left: 67.7%; // 921px

        picture {
          padding-bottom: 134.84%;
        }

        @media only screen and (max-width: $moduleImageGalleryContainer) {
          // width: 13.2%;
          width: 28%;
          left: auto;
          right: 21.7%;
          top: 65%;

          picture {
            padding-bottom: 134.4%;
          }
        }
      }

      &-05 {
        width: 22.79%; // 310px
        top: 66%; // 528px
        right: 0;

        picture {
          padding-bottom: 70.97%;
        }

        @media only screen and (max-width: $moduleImageGalleryContainer) {
          // width: 22.1%;
          width: 36.8%;
          left: 4%;
          right: auto;
          top: 58%;
        }
      }
    }
  }
}

.module {
  &__contact-form {
    .container {
      max-width: 860px + $padding-left + $padding-right;
    }
  }
}

.module__team-listing {
  margin-bottom: 120px;

  @media only screen and (max-width: $bs-xs) {
    margin-bottom: 90px;
  }

  h3 {
    text-align: center;
  }

  &--item {
    max-width: 420px;
    width: calc(420px / $container * 100%);
    margin-right: calc(20px / $container * 100%);
    text-align: center;
    cursor: pointer;
    position: relative;
    padding-bottom: 20px;

    @media only screen and (max-width: $bs-xs) {
      max-width: none;
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }

    picture {
      margin-bottom: 30px;
      display: block;
      width: 100%;
      // padding-bottom: 85%;
      position: relative;

      @media only screen and (max-width: $bs-xs) {
        margin-bottom: 20px;
      }

      img {
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 180px;

        @media only screen and (max-width: $bs-xs) {
          max-width: 260px;
        }
      }
    }

    h4 {
      margin-bottom: 30px;

      @media only screen and (max-width: $bs-xs) {
        margin-bottom: 20px;
      }
    }

    &--learn-more {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }

  .module__team-listing--popup-card {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;

    &__wrapper {
      background-color: #C4C32F;
      padding: 132px 164px;
      width: 50%;
      height: 100%;
      margin: 0 auto;
      position: fixed;
      overflow: auto;
      right: -100%;
      transition: right 0.5s ease-in-out;

      @media only screen and (max-width: $bs-md) {
        padding: 71px 20px 25px 20px;
      }

      @media only screen and (max-width: $bs-lg) {
        padding: 78px 20px 20px 20px;
      }

      @media only screen and (max-width: $bs-sm) {
        width: 100%;
      }
    }

    &.open {
      opacity: 1;
      pointer-events: auto;

      .module__team-listing--popup-card__wrapper {
        right: 0;
      }
    }

    &--close-button {
      span {
        display: none;
      }

      width: 35px;
      height: 35px;
      position: relative;
      background: none;
      border: 0;
      position: absolute;
      top: 40px;
      left: 40px;

      @media only screen and (max-width: $bs-md) {
        top: 23px;
        left: 20px;
      }

      &:before, &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #303030;
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: 0 0;
      }

      &:before {
        transform: rotate(45deg) translate(-50%, -50%);
      }

      &:after {
        transform: rotate(-45deg) translate(-50%, -50%);
      }
    }

    &--fulltext-toggler {
      span {
        display: none;
      }

      background: none;
      border: 0;
      position: relative;
      width: 11px;
      height: 11px;

      &:before, &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: #303030;
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: 0 0;
      }

      &:before {
        transform: translate(-50%, -50%);
      }

      &:after {
        transform: rotate(90deg) translate(-50%, -50%);
        opacity: 1;
        transition: opcity 0.5s ease-in-out;
      }
    }

    .module__team-listing--popup-card__wrapper.extended {
      .module__team-listing--popup-card--fulltext-toggler {
        &:after {
          opacity: 0;
        }
      }
    }

    header {
      margin-bottom: 40px;
      text-align: center;

      h3 {
        margin-bottom: 0;
      }

      .socialmedia-link {
        margin-left: 20px;
      }

      h4 {
        margin-top: 20px;
      }
    }
  }
}

