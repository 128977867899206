.checkout--listing-header,
.shopping-cart--listing-header {
	border-style: solid;
	border-color: var(--dtm-light-green);
	border-width: 1px 0;
	margin-top: px2vw(30px);
	padding: px2vw(15px);

	@media only screen and (min-width: $container) {
		margin-top: 30px;
		padding: 15px;
	}

	dt {
		font-family:"General Sans Medium";
		font-weight: 600;
		font-size: px2vw(20px);
		line-height: 1.35;

		@media only screen and (min-width: $container) {
			font-size: 20px;
		}
	}
}

.checkout--listing,
.shopping-cart--listing {
	&-item {
		margin-bottom: px2vw(30px)!important;

		&:last-child {
			margin-bottom: 0!important;
		}

		@media only screen and (min-width: $container) {
			margin-bottom: 30px!important;
		}
	}

	&--seller-products {
		dd {
			// margin-bottom: 0;
			margin-bottom: 30px;
		}

		padding-left: px2vw(15px);
		padding-right: px2vw(15px);
		border-bottom: 1px solid var(--dtm-light-green);
		margin-bottom: px2vw(30px);

		@media only screen and (min-width: $container) {
			margin-bottom: 30px;
			padding-left: 15px;
			padding-right: 15px;
		}

		@media only screen and (max-width: $bs-sm) {
			border: 0;
			margin-bottom: 0;
			margin-top: 0;
			padding: 30px;
			border-style: solid;
			border-color: var(--dtm-light-green);
			border-width: 2px 2px 0 2px;

			&:last-child {
				border-bottom-width: 2px;
			}
		}
	}

	&--seller {
		label {
			font-size: px2vw(18px);
			word-wrap: anywhere;

			@media only screen and (min-width: $container) {
				font-size: 18px;
			}

			@media only screen and (max-width: $bs-sm) {
				font-size: 16px;
			}
		}
	}

	&--seller-product {
		.checkout--listing-item,
		.shopping-cart--listing-item {
			&:last-child {
				dd {
					border-bottom: 1px solid var(--dtm-light-green);
					padding-bottom: px2vw(30px);

					@media only screen and (min-width: $container) {
						padding-bottom: 30px;
					}

					&:first-child {
						border-bottom: 0;
					}

					@media only screen and (max-width: $bs-sm) {
						dd { border-bottom: 0; }
					}
				}
			}

			@media only screen and (max-width: $bs-sm) {
				dd {
					border: 0!important;
					padding: 0!important;
					margin-bottom: 30px!important;
				}
			}
		}

		&:last-child {
			.checkout--listing-item,
			.shopping-cart--listing-item {
				dd { border-bottom: 0; padding-bottom: 0; }
			}
		}

		&:first-child {
			.checkout--listing-item:first-child,
			.shopping-cart--listing-item:first-child {
				label { visibility: visible; }
			}
		}

		.checkout--listing-item:first-child,
		.shopping-cart--listing-item:first-child {
			.shopping-cart--listing--seller {
				visibility: visible;

				@media only screen and (max-width: $bs-sm) {
					visibility: hidden;
				}
			}

			.shopping-cart--listing--action {
				.btn--transaction-specific {
					visibility: visible;

					@media only screen and (max-width: $bs-sm) {
						visibility: hidden;
					}
				}
			}
		}

		@media only screen and (max-width: $bs-sm) {
			.checkout--listing-item:last-child,
			.shopping-cart--listing-item:last-child {
				.shopping-cart--listing--action {
					.btn--transaction-specific {
						visibility: visible;
					}
				}
			}
		}
	}

	&--img {
		padding-right: 2%!important;
		
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: top;
		}
	}

	&--item-details {
		h3 {
			text-align: left;
			font-family:"General Sans Medium";
			font-weight: 600;
			font-size: px2vw(20px);
			line-height: 1.35;
			margin-bottom: px2vw(11px);

			a {
				color: var(--body-text);
				text-decoration: none;
			}

			@media only screen and (min-width: $container) {
				font-size: 20px;
				margin-bottom: 11px;
			}

			@media only screen and (max-width: $bs-sm) {
				font-size: 18px;
				line-height: 1.38;
				margin-bottom: 10px;
			}
		}

		.checkout--listing--item-text,
		.shopping-cart--listing--item-text {
			margin-bottom: px2vw(10px);
			font-size: px2vw(14px);
			line-height: 1.29;

			@media only screen and (min-width: $container) {
				margin-bottom: 10px;
				font-size: 14px;
			}

			@media only screen and (max-width: $bs-sm) {
				margin-bottom: 10px;
				font-size: 14px;
			}
		}
	}

	&-item--brand {
		color: var(--dtm-green);
		font-weight: 700;
		font-size: px2vw(19px);
		text-transform: uppercase;

		@media only screen and (min-width: $container) {
			font-size: 19px;
		}

		@media only screen and (max-width: $bs-sm) {
			font-size: 17px;
		}
	}

	&-item--meta-info {
		font-weight: 700;
		font-size: px2vw(20px);

		@media only screen and (min-width: $container) {
			font-size: 20px;
		}

		@media only screen and (max-width: $bs-sm) {
			font-size: 16px;
		}
	}

	&--transaction-type {
		text-align: center;
	}

	&--remove {
		text-align: center;

		@media only screen and (max-width: $bs-sm) {
			text-align: left;
		}

		button {
			background: none;
			border: 0;
			padding: 0;

			@media only screen and (max-width: $bs-xs) {
				width: 15px;
			}
		}
	}

	&--cost {
		font-size: px2vw(18px);

		@media only screen and (min-width: $container) {
			font-size: 18px;
		}

		@media only screen and (max-width: $bs-sm) {
			font-size: 15px;
		}
	}
}

.shopping-cart {
	.shopping-cart--listing--seller-product {
		.shopping-cart--listing-item {
			.shopping-cart--listing--seller {
				visibility: hidden;
			}
		}
	}
}

// .checkout--listing {
	// &--seller {
		// display: none;
	// }
// }

.checkout {
	.checkout--listing--seller-product {
		.checkout--listing--seller {
			visibility: hidden;

			// @media only screen and (max-width: $bs-sm) {
				// display: none;
			// }
		}

		&:first-child {
			.checkout--listing--seller {
				visibility: visible;

				// @media only screen and (max-width: $bs-sm) {
					// display: block;
				// }
			}
		}
	}
}

.shopping-cart--listing {
	&--seller {
		flex-basis: 16%;
	}

	&--img {
		flex-basis: 9%;
	}

	&--item-details {
		flex-basis: 21%;
	}

	&--transaction-type {
		flex-basis: 20%;
	}

	&--remove {
		flex-basis: 10%;

		@media only screen and (max-width: $bs-sm) {
			flex-basis: 50%;
		}
	}

	&--cost {
		flex-basis: 9%;

		@media only screen and (max-width: $bs-sm) {
			flex-basis: 50%;
		}
	}

	&--action {
		flex-basis: 15%;
	}
}

.checkout--listing {
	&--seller {
		flex-basis: 16%;
	}

	&--img {
		flex-basis: 9%;
	}

	&--item-details {
		flex-basis: 36%;
	}

	&--transaction-type {
		flex-basis: 20%;
	}

	&--remove {
		flex-basis: 10%;

		@media only screen and (max-width: $bs-sm) {
			flex-basis: 50%;
		}
	}

	&--cost {
		flex-basis: 9%;

		@media only screen and (max-width: $bs-sm) {
			flex-basis: 50%;
		}
	}
}

.btn--transaction-specific {
	background: none;
	border: 0;
	text-transform: uppercase;
	font-family:"Gotham-Bold";
	font-weight: 400;
	font-size: px2vw(16px);
	line-height: 1.56;
	width: 100%;
	padding: px2vw(19px) 0 px2vw(15px) 0;
	visibility: hidden;

	@media only screen and (min-width: $container) {
		font-size: 16px;
		padding: 19px 0 15px 0;
	}

	@media only screen and (max-width: $bs-sm) {
		max-width: 160px;
		font-size: 16px;
		padding: 19px 0 15px 0;
	}

	&.product-sell {
		background-color: var(--dtm-yellow);
	}

	&.product-swap-donation,
	&.product-swap {
		background-color: var(--dtm-light-green);
	}
}

.shopping-cart {
	&--empty {
		margin-top: 120px;
	}

	&--main {
		@media only screen and (max-width: $bs-sm) {
			margin-top: 30px;
		}

		dt, dd {
			padding-right: 1%;
			&:last-child { padding-right: 0; }

			@media only screen and (max-width: $bs-sm) {
				padding-right: 0;
			}
		}
	}
}

.checkout--listing--seller-products {
	border-bottom: 0;
	padding-bottom: 0;
	margin-bottom: 0;
}

.checkout--grand-total {
	padding: 20px 17px;
	position: relative;
	border-color: var(--dtm-light-green);
	border-style: solid;
	border-width: 1px 0;
	margin-top: 30px;

	@media only screen and (max-width: $bs-sm) {
		// margin-top: -20px;
		margin-top: 0;
	}

	&:after {
		content: "";
		opacity: 0.3;
		background-color: var(--dtm-light-green);
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
	}

	& * {
		font-family:"General Sans Medium";
		font-weight: 600;
		font-size: 40px;
		line-height: 1;

		@media only screen and (max-width: $bs-sm) {
			font-size: 24px;
		}
	}

	label {
		font-size: 20px;
		margin-right: 45px;

		@media only screen and (max-width: $bs-sm) {
			font-size: 18px;
		}
	}
}

.checkout--stripe-form {
	margin-bottom: 60px;

	@media only screen and (max-width: $bs-xs) {
		margin-bottom: 50px;
	}

	.container {
		max-width: 712px;

		@media only screen and (max-width: $bs-sm) {
			padding: 0;
		}
	}
}

.checkout--agreements {
	margin-bottom: 60px;

	@media only screen and (max-width: $bs-sm) {
		margin-bottom: 50px;
	}

	&, .row {
		margin-top: 30px;
	}

	.container {
		max-width: 712px;

		@media only screen and (max-width: $bs-sm) {
			padding: 0;
		}
	}
}

.checkout {
	.btn--transaction-specific {
		visibility: visible;
		max-width: 420px;
	}

	&--thankyou-message {
		.container {
			max-width: 860px;
		}

		h2 {
			font-family:"General Sans Medium";
			font-weight: 600;
			font-size: 40px;
			line-height: 1.25;
			margin-bottom: 60px;
		}

		&--buttons {
			margin-top: 71px;

			button, a {
				margin-left: 30px;
				margin-right: 30px;
			}
		}
	}
}
