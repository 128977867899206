.breadcrumb {
	a {
		display: inline-block;
		text-decoration: none;
		color: var(--body-text);
		font-size: 14px;

		&:after {
			content: "";
			background-image: url(../img/icon/arrow-EB6723.svg);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			width: 18px;
			height: 8px;
			display: inline-block;
			transform: rotate(-90deg);
		}

		&:last-child:after { display: none; }
	}
}
