.profile-edit--credit {
	max-width: calc(1080px + $padding-left + $padding-right);

	.profile {
		grid-column-gap: calc(130em / 18);

		section {
			margin-bottom: 64px;

			@media only screen and (max-width: $bs-xs) {
				margin-bottom: 50px;
			}

			&:last-child { margin-bottom: 0; }
		}

		.pagination { margin-top: 30px; }
	}

	.profile--credit--transactions {
		th, td {
			text-align: center;
			padding: 15px;
			background: none;
			box-shadow: none;
			border-bottom: 1px solid var(--dtm-light-green);
			font-size: px2vw(17px);

			@media only screen and (min-width: $container) {
				font-size: 17px;
			}
		}

		.profile--credit--transaction {
			&-balance {
				padding: 20px;
				margin-bottom: 15px;
				position: relative;

				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
					width: 100%;
					height: 100%;
					background-color: var(--dtm-light-green);
					opacity: 0.3;
				}

				h3, h5 { margin-bottom: 0; }
			}
		}
	}

	.profile--credit--top-up {
		&--wrapper {
			position: relative;
			padding: 30px;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				background-color: var(--dtm-yellow);
				opacity: 0.1;
			}

			.loading-wheel {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
			}
		}

		&--stripe-form {
			margin-bottom: 30px;
		}
	}

	.profile--credit--redeem {
		&--wrapper {
			position: relative;
			padding: 30px;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				background-color: var(--dtm-orange);
				opacity: 0.1;
			}
		}
	}

	.profile--credit--top-up,
	.profile--credit--redeem {
		.pretty-select {
			margin-bottom: 30px;
		}

		.modal-box--wrapper {
			width: 60%;
		}
	}
}

