.user-auth {
	.container {
		max-width: 639px;
	}

	&---message {
		margin-top: 30px;
	}
}

.user-auth--login--utility {
	margin-top: 30px;

	span {
		a { white-space: nowrap }

		display: inline-block;
		margin-right: 10px;
		&:last-child { margin-right: 0 }

		@media only screen and (max-width: $bs-xs) {
			margin-bottom: 20px;
			&:last-child { margin-bottom: 0 }
		}
	}

	button {
		@media only screen and (max-width: $bs-xs) {
			margin-bottom: 50px;
		}
	}
}

.user-registration {
	.container {
		max-width: 639px;
	}

	form {
		section {
			margin: 60px 0;
		}

		label {
			span {
				color: var(--dtm-orange);
				display: inline-block;
				margin-left: 10px;
			}
		}
	}
}

.account-activation, .user-registration--successful-message {
	.container {
		max-width: calc(640px + $padding-left + $padding-right);
	}

	h2 {
		font-family:"General Sans Regular";
		font-weight: 400;
		font-size: 40px;
		line-height: 1.25;
		margin-bottom: 30px;

		@media only screen and (max-width: $bs-xs) {
			font-size: 28px;
			margin-bottom: 15px;
		}
	}
}

.user-auth--forgotpassword {
	&--show {
		display: block!important;
	}
}

