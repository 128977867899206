.faq-listing {
  .page-header {
    margin-bottom: 60px;
    
    @media only screen and (max-width: $bs-xs) {
      margin-bottom: 39px;
    }
  }
  
  &__group {
    margin-bottom: 60px;

    @media only screen and (max-width: $bs-xs) {
      margin-bottom: 50px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-item {
      border-bottom: 1px solid #C4C32F;
      margin-bottom: 20px;

      @media only screen and (max-width: $bs-xs) {
        margin-bottom: 15px;
      }
    }

		.accordion {
			.ui-accordion-header {
				padding: 0;
				cursor: pointer;
				margin-bottom: 20px;
				position: relative;
				padding-right: 50px;
				font-family:"Gotham-Bold";
				font-weight: 500;
				font-size: 18px;
				line-height: 1.27;

				@media only screen and (max-width: $bs-xs) {
					margin-bottom: 15px;
				}

				.ui-accordion-header-icon {
					&:before, &:after {
						background-color: #C4C32F;
					}
				}

				& + div {
					margin-bottom: 30px;
				}
			}
		}
  }
}
