@font-face {
  font-family:"Gotham-Bold";
  src:url("fonts/Gotham Bold/Gotham Bold.woff2") format("woff2"),url("fonts/Gotham Bold/Gotham Bold.woff") format("woff"),url("fonts/Gotham Bold/Gotham Bold.otf") format("opentype");
    font-style:normal;font-weight:400;
}

@font-face {
  font-family:"Gotham-Book";
  src:url("fonts/Gotham Book Regular/Gotham Book Regular.woff2") format("woff2"),url("fonts/Gotham Book Regular/Gotham Book Regular.woff") format("woff"),url("fonts/Gotham Book Regular/Gotham Book Regular.otf") format("opentype");
    font-style:normal;font-weight:400;
}

@font-face {
	font-family:"Gotham-Medium";
	src:url("fonts/GothamMedium/GothamMedium.woff2") format("woff2"),url("fonts/GothamMedium/GothamMedium.woff") format("woff"),url("fonts/GothamMedium/GothamMedium.otf") format("opentype");
	font-style:normal;font-weight:400;
}

@font-face {
  font-family:"General Sans Bold";
  src:url("fonts/GeneralSans-Bold/GeneralSans-Bold.woff2") format("woff2"),url("fonts/GeneralSans-Bold/GeneralSans-Bold.woff") format("woff"),url("fonts/GeneralSans-Bold/GeneralSans-Bold.otf") format("opentype");
  font-style:normal;font-weight:400;
}

@font-face {
	font-family:"General Sans Medium";
	src:url("fonts/GeneralSans-Medium/GeneralSans-Medium.woff2") format("woff2"),url("fonts/GeneralSans-Medium/GeneralSans-Medium.woff") format("woff"),url("fonts/GeneralSans-Medium/GeneralSans-Medium.otf") format("opentype");
	font-style:normal;font-weight:400;
}

@font-face {
	font-family:"General Sans Semibold";
	src:url("fonts/GeneralSans-Semibold/GeneralSans-Semibold.woff2") format("woff2"),url("fonts/GeneralSans-Semibold/GeneralSans-Semibold.woff") format("woff"),url("fonts/GeneralSans-Semibold/GeneralSans-Semibold.otf") format("opentype");
	font-style:normal;font-weight:400;
}

@font-face {
	font-family:"General Sans Regular";
	src:url("fonts/GeneralSans-Regular/GeneralSans-Regular.woff2") format("woff2"),url("fonts/GeneralSans-Regular/GeneralSans-Regular.woff") format("woff"),url("fonts/GeneralSans-Regular/GeneralSans-Regular.otf") format("opentype");
	font-style:normal;font-weight:400;
}

$chinese-font-stack: 'Hiragino Sans GB', 'Microsoft JhengHei', STXihei, sans-serif;

:root {
	--dtm-green: #178043;
	--dtm-light-green: #C4C32F;
	--dtm-gold: #C4C32F;
	--dtm-cream: #FAFBEC;
	--dtm-orange: #EB6723;
	--dtm-yellow: #FDC422;
	// --dtm-sell: #FDC422;
	// --dtm-swap: #C4C32F;
	// --dtm-swap-donation: #EB6723;
	--dark-grey: #7D7D7D;
	--body-text: #303030;
	--background-color: #FFFEFA;
	--error: #DC0000;
}

$container: 1300px;
$padding-left: 30px;
$padding-right: 30px;

$bs-lg: 1199px;
$bs-md: 991px;
$bs-sm: 767px;
$bs-xs: 575px;

@function px2vw($property) {
  @return #{calc($property/$container*100)}vw;
}

/* Automatically clear elements for standards-compliant browsers */
.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}
/* Trigger has-layout for IE compatibility */
.clearfix {
	zoom: 1;
}

#screen_measurement {
	position: fixed; bottom: 0px; left: 0px; z-index: 999; background-color: rgb(255, 255, 255); font-size: 11px; font-weight: 700; line-height: 15px;

	display: none;
}
#screen_measurement > * { color: #000000; }
#screen_measurement #display_classes > div { padding: 0 5px; }

.error {
	color: #ff0000;

	iframe, // this is in particular for Google reCaptcha
	input, textarea {
		border-bottom: 1px solid #ff0000;
	}
}

.message {
	color: #006e00;
}

img {
	max-width: 100%;
	height: auto;
}

.map_canvas img { max-width: none; } /* fix the google map issue */

.ajax-show-content {
	opacity: 1!important;
	pointer-events: visible!important;
}

/*
 * project styling START
 */

body {
	/*overflow-y: scroll;*/ /* has to be scroll, not auto */
	-webkit-overflow-scrolling: touch;
  background: #FFFEFA;
	color: var(--body-text);
  font-family:"Gotham-Book";
	font-size: 18px;
	line-height: 1.277;

	@media only screen and (max-width: $bs-sm) {
		font-size: 15px;
	}
}

button {
	color: var(--body-text);
}

.body-small {
	font-size: 14px;
	line-height: 1.29;

	&--bold {
		font-family:"Gotham-Bold";
		font-weight: 350px;
		font-size: 14px;
		line-height: 1.29;
	}
}


strong {
  font-family:"Gotham-Bold";
	font-weight: 500;
}

a {
	color: var(--dtm-green);
	text-decoration: underline;

  &:focus, &:hover { color: inherit; }
}

.container {
	max-width: $container + $padding-left + $padding-right;
	padding-left: $padding-left;
	padding-right: $padding-right;
}

main.template {
	padding-top: 60px;
	padding-bottom: 60px;

	@media only screen and (max-width: $bs-xs) {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

body.have-banner {
	main.template {
		padding-top: 0;
	}
}

p, ul, ol, dl, table {
	margin-bottom: 30px;

	// &:last-child {
		// margin-bottom: 0;
	// }
}

ul, ol {
	padding-left: 20px;
}

ul {
	list-style: none;

	li {
		position: relative;

		&:before {
			content: ".";
			position: absolute;
			left: -10px;
			top: -4px;
		}
	}

	&.list-unstyled {
		li:before { display: none; }
	}
}

h1, h2, h3, h4 {
  margin-bottom: 30px;

  @media only screen and (max-width: $bs-sm) {
    margin-bottom: 20px;
  }
}

h1 {
	font-family:"General Sans Medium";
	font-weight: 600;
	font-size: 60px;
	line-height: 1.16;

	@media only screen and (max-width: $bs-sm) {
		font-size: 40px;
	}
}

h2 {
  font-family:"General Sans Bold";
	font-weight: 700;
	font-size: 60px;
	line-height: 1.066;

	@media only screen and (max-width: $bs-sm) {
		font-size: 35px;
	}
}

.page-title, h3 {
	font-family:"General Sans Regular";
	font-weight: 600;
	font-size: 40px;
	line-height: 1.25;

	@media only screen and (max-width: $bs-sm) {
		font-size: 28px;
	}
}

.page-title {
	text-align: center;
}

h4 {
	font-family:"General Sans Medium";
	font-weight: 600;
	font-size: 30px;
	line-height: 1.26;

	@media only screen and (max-width: $bs-sm) {
		font-size: 22px;
	}
}

h5 {
	font-family:"General Sans Medium";
	font-weight: 600;
	font-size: 20px;
	line-height: 1.35;
  margin-bottom: 20px;
}

h6 {
	font-family:"General Sans Medium";
	font-weight: 600;
	font-size: 20px;
	line-height: 1.35;
  margin-bottom: 20px;
}

.navbar-brand { padding: 0; }

.text-link {
  text-decoration: none!important;
  display: inline-block!important;
  text-transform: uppercase!important;
  background: none!important;
  padding: 0!important;
  border: 0!important;
  font-family:"Gotham-Bold"!important;
  font-weight: 400!important;
  font-size: 16px!important;
  line-height: 1.1!important;
	cursor: pointer!important;

  &--underline {
    position: relative!important;

    &:after {
      content: ""!important;
      display: block!important;
      height: 2px!important;
			margin-top: 3px!important;
    }
  }

	&._178043 {
		color: #178043!important;

		&:after {
			background-color: var(--dtm-light-green)!important;
		}
	}

  &._eb6723 {
    color: #eb6723!important;

    &:after {
      background-color: var(--dtm-yellow)!important;
    }
  }

  &._fdc422 {
    color: #fdc422!important;

    &:after {
      background-color: #fdc422!important;
    }
  }

  &._303030_7edbd5 {
    color: #303030!important;

    &:after {
      background-color: #7edbd5!important;
    }
  }

  &._303030_eb6723 {
    color: #303030!important;

    &:after {
      background-color: #eb6723!important;
    }
  }

  &._303030_fdc422 {
    color: #303030!important;

    &:after {
      background-color: #fdc422!important;
    }
  }
}

.button-square {
	text-transform: uppercase!important;
  font-family:"Gotham-Bold"!important;
	font-weight: 500!important;
	font-size: px2vw(16px)!important;
	line-height: 1!important;
	border: 0!important;
	padding: px2vw(15px) px2vw(13.5px) px2vw(11px) px2vw(13.5px)!important;
	text-decoration: none!important;

	@media only screen and (min-width: $container) {
		font-size: 16px!important;
		padding: 15px 13.5px 11px 13.5px!important;
	}

	@media only screen and (max-width: $bs-xs) {
		font-size: 16px!important;
		padding: 15px 13.5px 11px 13.5px!important;
	}

	&._FAFBEC_303030_FAFBEC {
    background-color: #FAFBEC;
    border: 1px solid #C4C32F;
    color: rgba(48, 48, 48, 0.8);
	}

	&._FDC422-303030 {
		background-color: var(--dtm-yellow)!important;
		color: #303030!important;
	}

	&._FAFBEC-C4C32F {
		background-color: #FAFBEC!important;
		border: 1px solid #C4C32F!important;
	}

	&._C4C32F-303030 {
		background-color: #C4C32F!important;
		color: #303030!important;
	}

	&._7D7D7D-FFFEFA {
		background-color: #7D7D7D!important;
		color: #FFFEFA!important;
	}

	&._303030-FFFEFA {
		background-color: #303030!important;
		color: #FFFEFA!important;
	}
}

.button--add-to-cart {
	&:before {
		content: "+";
	}

	&--disabled:before { display: none; }
}

.text-box {
	padding: 20px;
	position: relative;
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}

	h6 {
		margin-bottom: 15px;
	}

	&--content {
		padding-top: 20px;
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
	}

	&._EB6723 {
		&:after {
			background-color: #EB6723!important;
			opacity: 0.2;
		}

		.text-box--content {
			border-top: 1px solid #EB6723!important;
		}
	}

	&._FAFBEC_C4C32F {
		color: #4D4D4D!important;

		&:after {
			background-color: #FAFBEC!important;
		}

		.text-box--content {
			border-top: 1px solid #C4C23F!important;
		}
	}
}

.page-header {
	text-align: center;
	margin-top: 60px;
	margin-bottom: 60px;

	@media only screen and (max-width: $bs-sm) {
		margin-top: 0;
		margin-bottom: 30px;
	}
}

#banner {
  position: relative;
  padding: 83px 0;

  @media only screen and (max-width: $bs-xs) {
    padding: 70px 0;
  }

  .banner__content-text * {
    font-family:"Gotham-Bold";
    font-weight: 400;
  }

  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .banner__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.7;
    }
  }

  .banner__content {
    position: relative;
    text-align: center;

    .container {
      max-width: 640px + $padding-left + $padding-right;
    }

    & * {
      color: #FFFEFA!important;
    }

    h1 {
      margin-bottom: 30px;
    }

    &-text {
      margin-bottom: 30px;

      @media only screen and (max-width: $bs-xs) {
        margin-bottom: 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.section__intro {
  margin: 120px 0;

  @media only screen and (max-width: $bs-xs) {
    margin: 90px 0;
  }

  .container {
    max-width: 860px + $padding-left + $padding-right;
  }
}

