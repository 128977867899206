.header--lang-switch {
	position: relative;
	// margin: 0 px2vw(15px);

	// @media only screen and (min-width: $container) {
		// margin: 0 15px;
	// }

	ul {
		opacity: 0;
		pointer-events: none;

		li:before { display: none; }
	}

	&.active {
		ul {
			opacity: 1;
			pointer-events: auto;
		}
	}

	button {
		text-transform: uppercase;
		background: none;
		border: 0;
		padding: 0;
		font-family:"General Sans Medium";
		font-weight: 500;
		font-size: 17px;
		line-height: 1.1;
		margin: 0 px2vw(15px);

		@media only screen and (min-width: $container) {
			margin: 0 15px;
		}
	}

	ul {
		list-style: none;
		padding-left: 0;
		text-align: center;
		position: absolute;
		top: 30px;
		left: 50%;
		z-index: 2;
		transform: translate(-50%,0);
		width: 100%;
		margin-top: 20px;
		margin-bottom: 0;
		background-color: #EDECBD;
		padding: px2vw(15px) px2vw(10px);

		@media only screen and (min-width: $container) {
			padding: 15px 10px;
		}

		li {
			line-height: 1;
		}

		a {
			font-family:"General Sans Regular";
			font-weight: 400;
			color: var(--dtm-body-text);
			padding: 7.5px 0!important;
			display: block;
			margin: 0;

			@media only screen and (min-width: $container) {
				padding: 7.5px 0;
			}

			&.active, &:hover {
				font-family:"General Sans Bold";
				font-weight: 700;
			}
		}
	}
}
